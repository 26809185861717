import '../../App.scss';
import '../../css/modals.scss';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import BaseForm from '../BaseForm';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function AddOrEditVenueCategoryModal(props) {
    const { t } = useTranslation('common');

    return (
        <BaseAddOrEditItemModal
            {...props}
            size="lg"
            itemLabel={t("modal.venue_category.title")}
            itemBaseUrl={'/venue_categories'}
        >
            <Row>
                <BaseForm.Input colSpan={4} name={'categoryName'} label={t("add_venue_category_modal.category_name")} type='text' required />
                <BaseForm.Input colSpan={4} name={'categoryLink'} label={t("add_venue_category_modal.category_link")} type='text' required />
                <BaseForm.Input colSpan={4} name={'companyName'} label={t("add_venue_category_modal.company_name")} type='text'/>
                <BaseForm.Input colSpan={4} name={'address1'} label={t("add_venue_category_modal.street_address")} type='text'/>
                <BaseForm.Input colSpan={4} name={'address2'} label={t("add_venue_category_modal.street_address_2")} type='text'/>
                <BaseForm.Input colSpan={4} name={'city'} label={t("add_venue_category_modal.city")} type='text'/>
                <BaseForm.Input colSpan={4} name={'state'} label={t("add_venue_category_modal.province")} type='text'/>
                <BaseForm.Input colSpan={4} name={'zipCode'} label={t("add_venue_category_modal.postal_code")} type='text'/>
                <BaseForm.Input colSpan={4} name={'phone'} label={t("common.phone")} type='text'/>
                <BaseForm.Input colSpan={4} name={'useAddress'} label={t("add_venue_category_modal.use_address")} type='check'/>
                <BaseForm.Input colSpan={4} name={'hideFromUser'} label={t("add_venue_category_modal.hide")} type='check'/>
                <BaseForm.Input colSpan={12} name={'userInstruction'} label={t("add_venue_category_modal.instructions")} type='editor'/>
            </Row>
        </BaseAddOrEditItemModal>
    );

}

export default AddOrEditVenueCategoryModal;
