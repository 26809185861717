import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import SingleAgreementEmailModal from '../../components/modals/SingleAgreementEmailModal';
import ChooseAgreementMonthModal from '../../components/modals/ChooseAgreementMonthModal';
import InvoiceAgreementPaymentModal from '../../components/modals/InvoiceAgreementPaymentModal';
import Loader from '../../components/Loader';
import BaseContainer from '../../components/Container';
import BaseForm from '../../components/BaseForm';
import ConfirmationButton from '../../components/ConfirmationButton';
import DeleteButton from '../../components/DeleteButton';
import TabHeader from '../../components/TabHeader';
import Notification from '../../components/Notification';
import SubmitButton from '../../components/SubmitButton';
import SingleSelectDropdown from '../../components/SingleSelectDropdown';
import { serverFetch, serverPost, serverDelete, getInvoiceCreationUrl } from '../../helpers/server';
import { conciseTimeDisplay, downloadBlob, hasAccess, BaseContext, getFormattedImageLink,
    currencyFormat, getStripePublishableKeyForFacility, getDateFormatForFacility } from '../../helpers/common';
import { getTabItems } from '../../helpers/tabs'
import { useParams, useSearchParams, Link, useNavigate } from "react-router-dom";
import { Table, Button, Row, Col, Alert, ButtonToolbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classnames from 'classnames';
import { loadStripe } from '@stripe/stripe-js';
const _ = require("lodash");

function AgreementAccept() {
    const { userInfo, isLoggedIn, getApiUrl, settings, getFacilityName } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(true);
    const [ searchParams ] = useSearchParams();
    let uuid = searchParams.get('uuid');

    // For the payment processing results
    let name = searchParams.get('name');
    let invoiceFee = searchParams.get('fee');
    let processor = searchParams.get('processor');
    let paymentIntent = searchParams.get('payment_intent');
    let paymentIntentClientSecret = searchParams.get('payment_intent_client_secret');
    let redirectStatus = searchParams.get('redirect_status');

    const [groupInfo, setGroupInfo] = useState({});
    const [credit, setCredit] = useState(0);
    const [groupId, setGroupId] = useState(null);
    const [agreementDetails, setAgreementDetails] = useState({});
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [initialPaymentFormFields, setInitialPaymentFormFields] = useState({});
    const [initialAcceptFormFields, setInitialAcceptFormFields] = useState({});
    const [taxRateMap, setTaxRateMap] = useState({});
    const [showAgreementEmailModal, setShowAgreementEmailModal] = useState(false);
    const [showChooseAgreementMonthModal, setShowChooseAgreementMonthModal] = useState(false);
    const [showInvoicePaymentModal, setShowInvoicePaymentModal] = useState(false);
    const [bookingLineItemsToInvoice, setBookingLineItemsToInvoice] = useState([]);
    const isAgreementAdmin =  hasAccess("agreement", userInfo);
    const showPaymentForm = isLoggedIn && agreementDetails.amountDue > 0 && !agreementDetails.isCancel;

    useEffect(() => {
        const groupName = !_.isEmpty(agreementDetails) ? agreementDetails.group.name : ""
        document.title = `${groupName} (${agreementDetails.agreementNumber}) - ${getFacilityName()}`;

        if (!_.isEmpty(agreementDetails)) {
            const taxRateMap = agreementDetails.lineItems.reduce((map, line) => {
                const key = line.taxLabel ? `${line.taxLabel} ${line.taxRate}%` : `${agreementDetails.taxLabel} ${line.taxRate}%`;
                map[key] = (map[key] || 0) + (line.total - line.amount);
                return map;
            }, {});
            setTaxRateMap({...taxRateMap})
        }
        
    }, [agreementDetails]);

    useEffect(() => {
        fetchData(true);
    }, []);

    useEffect(async () => {
        if (!paymentIntent) {
            return;
        }
        if (processor !== "stripe") {
            return;
        }
        const stripe = await loadStripe(getStripePublishableKeyForFacility(settings));
        const pi = await stripe.retrievePaymentIntent(paymentIntentClientSecret)
        const fee = invoiceFee ? parseFloat(invoiceFee): 0.0
        if (pi.paymentIntent.status === "succeeded") {
            const data = {
                redirectStatus: pi.paymentIntent.status,
                paymentProcessorData: {
                    stripe: {
                        paymentIntentId: paymentIntent
                    }
                },
                amount: (parseFloat(pi.paymentIntent.amount) / 100.0) - fee,
                paymentProcessor: "stripe",
                cardHolder: name,
                invoiceFee: fee,
            }

            console.log("The data is " + JSON.stringify(data));
            setTimeout(() => {
                Notification.Show(t('agreement.accept.payment_successful'));
            }, 100)
            serverPost(getApiUrl(`/agreements/${uuid}/online_pay`), data).then((res) => {
                let urlParser = new URL(window.location.href);
                urlParser.searchParams.delete('payment_intent');
                urlParser.searchParams.delete('payment_intent_client_secret');
                urlParser.searchParams.delete('redirect_status');
                urlParser.searchParams.delete('processor');
                urlParser.searchParams.delete('fee');
                urlParser.searchParams.delete('name');
                navigate(urlParser.pathname + urlParser.search, { replace: true });
                fetchData(true);
            })
        }
    }, [processor, name, invoiceFee, paymentIntent, paymentIntentClientSecret, redirectStatus]);

    useEffect(() => {
        if (groupId && isLoggedIn) {
            serverFetch(getApiUrl(`/user_groups/${groupId}`)).then((res) => {
                if (res) {
                    setGroupInfo(res);
                }
            });
        }
    }, [groupId]);

    const fetchData = (skipCache = false) => {
        serverFetch(getApiUrl(`/agreements/${uuid}`), { skipCache }).then((res) => {
            if (res) {
                res.creditCardFeeBaseAmountInCents = settings.creditCardFeeBaseAmountInCents;
                res.creditCardFeePercent = settings.creditCardFeePercent;
                res.creditCardFeeUsage = settings.creditCardFeeUsage;
                res.creditCardMinAmountInCents = settings.creditCardMinAmountInCents;
                setAgreementDetails(res);
                if (res.agreementOnlinePaymentOption === "no") {
                    setPaymentMethods([]);
                } else {
                    setPaymentMethods(res.paymentMethodOptions);
                }
                const acceptFields = {
                    'groupName': res.group.name,
                };
                if (res.isAccepted) {
                    const acceptedData = _.find(res.payments, (p) => p.amount === 0);
                    acceptFields['fullName'] = acceptedData && acceptedData.userName;
                    acceptFields['email'] = acceptedData && acceptedData.emailAgr;
                    acceptFields['phone'] = acceptedData && acceptedData.phoneAgr;
                    acceptFields['paymentMethod'] = acceptedData && acceptedData.paymentMethod;
                }
                setInitialAcceptFormFields(acceptFields)
                setGroupId(res.group.id);

                setInitialPaymentFormFields({
                    'amountPaid': Number(res.amountDue).toFixed(2),
                    'datePaid': moment().format("YYYY-MM-DD")
                });
            }
            setLoading(false);
        });
    }

    const cancelAgreement = () => {
        serverPost(getApiUrl(`/agreements/batch_cancel`), { uuids: [uuid] }).then((res) => {
            if (res) {
                Notification.Show(t('agreement.accept.successfully_deleted'));
                fetchData(true);
            }
        });
    }

    useEffect(() => {
        uuid = searchParams.get('uuid')
    }, [searchParams]);

    const getPaymentLabelForPaymentMethod = (paymentMethod) => {
        const method = _.find(paymentMethods, (m) => m.paymentTag === paymentMethod);
        return !_.isNil(method) ? method.paymentType : null;
    }

    const onLinkClick = () => {
        prompt("Copy to clipboard: Ctrl+C, Enter", `${window.location.origin}/${facilityLink}/agreement/accept?uuid=${uuid}`);
    }

    const onPrint = () => {
        if (_.isEmpty(agreementDetails)) {
            Notification.Show(t('agreement.accept.error.wait_for_load_to_print'));
            return;
        }
        const url = getApiUrl(`/agreements/${uuid}/pdf`);
        serverPost(url, {}, { noJson: true }).then((res) => {
            downloadBlob(res, `Agreement-${agreementDetails.agreementNumber}`);
        });
    }

    const editBooking = (booking) => {
        console.log("Edit booking " + booking.bookingId);
    }

    const addPayment = (paymentFields) => {
        console.log("Add payment " + JSON.stringify(paymentFields));
    }

    const deletePayment = (payment) => {
        console.log("Deleting payment");
    }

    const onMark = (flag) => {
        let url;
        if (flag) {
            url = getApiUrl(`/agreements/${agreementDetails.uuid}/set_accepted`)
        } else {
            url = getApiUrl(`/agreements/${agreementDetails.uuid}/unaccept`)
        }
        serverPost(url).then((res) => {
            if (res) {
                if (flag) {
                    Notification.Show(t('agreement.accept.mark_as_accepted'));
                } else {
                    Notification.Show(t('agreement.accept.mark_as_unaccepted'));
                }
                fetchData(true);
            }
        });
    }

    const onInvoiceSelected = (value) => {
        const bookings = _.filter(agreementDetails.lineItems, (item) => !_.isNil(item.bookingId));
        const otherRows = _.filter(agreementDetails.lineItems, (item) => _.isNil(item.bookingId));
        const filteredBookings = _.filter(bookings, (b) => _.isNil(b.invoice));
        if (filteredBookings.length === 0) {
            Notification.Show(t('agreement.accept.all_invoiced'));
            return;
        }
        if (value === "full") {
            navigate(getInvoiceCreationUrl(facilityLink, groupId, _.map(filteredBookings, (b) => b.bookingId), otherRows));
        } else {
            setBookingLineItemsToInvoice(filteredBookings);
            setShowChooseAgreementMonthModal(true);
        }
    }

    const onAcceptError = async (response, errorMessage) => {
        if (errorMessage.paymentMethod) {
            Notification.Show(errorMessage.paymentMethod);
        }
    }

    const onAccept = (data) => {
        serverPost(getApiUrl(`/agreements/${uuid}/accept`), data, {}, onAcceptError).then((res) => {
            if (res) {
                fetchData(true);
            }
        })
    }

    const onPay = () => {
        setShowInvoicePaymentModal(true);
    }

    const onModalClose = (didUpdate) => {
        setShowInvoicePaymentModal(false);
        fetchData(true);
    }

    const paymentMethodOptions = _.map(paymentMethods, (m) => { return { 'value': m.paymentTag, 'label': _.replace(m.paymentType, "Paid ", ""), 'icon': m.paymentIcon }; });

    const invoiceOptions = [
        { 'id': "full", 'label': t('agreement.accept.full_agreement'), 'icon': 'fa-file' },
        { 'id': "months", 'label': t('agreement.accept.choose_months'), 'icon': 'fa-calendar' },
    ];

    const tabsParams = {
        searchParams: groupId && `groupId=${groupId}`,
        groupId: groupId,
        userGroup: agreementDetails.group && {
            'groupName': agreementDetails.group.name
        },
        userInfo: userInfo
    };
    const tabs = getTabItems(t, facilityLink, "agreements", tabsParams);
    if (groupId && tabs[2]) {
        tabs[2].active = true;
    }

    let tableColumnWidths = {
        "hours": "10%",
        "rate": "10%",
        "tax": "10%",
        "amount": "10%"
    };
    if (settings.showAgrEventName) {
        tableColumnWidths["item"] = "10%";
        tableColumnWidths["venue"] = "14%";
        tableColumnWidths["description"] = "22%";
        tableColumnWidths["event"] = "14%";
    } else {
        tableColumnWidths["item"] = "10%";
        tableColumnWidths["venue"] = "20%";
        tableColumnWidths["description"] = "30%";
    }

    let iAgreeLabel = t('agreement.accept.i_agree');
    if (settings.customIAgree) {
        iAgreeLabel = <div dangerouslySetInnerHTML={{ __html: settings.customIAgree }} />;
    }

    const needsLogin = settings.needsLoginToAcceptAgreement;
    const isExpectedUser = !_.isEmpty(groupInfo) ? groupInfo.user.id === userInfo.id : false;
    const isExpired = agreementDetails.dueDateLocal && moment(agreementDetails.dueDateLocal, "YYYY-MM-DD hh:mm:ss").isBefore(moment()) && !agreementDetails.isAccepted && !agreementDetails.isCancel;
    const showAcceptForm = (!needsLogin || (isLoggedIn && isExpectedUser)) && !isExpired;

    return (
        <BaseContainer>
            <Loader loading={loading}>
                {
                    isLoggedIn && isAgreementAdmin &&
                        <TabHeader items={tabs}/>
                }
                <div className="content-box">
                    <div className="content-body">
                        <Row>
                            <div className="col-md-6">
                            </div>
                            <div className="col-md-6 text-end">
                                {
                                    credit > 0 &&
                                        <span className="label label-info" style={{ marginRight: "5px" }}><i className="fa fa-user"></i> {t('agreement.accept.credit_balance', { credit: credit })}</span>
                                }
                                {
                                    agreementDetails.isAccepted &&
                                        <span className="label label-success" style={{ marginRight: "5px" }}><i className="fa fa-check-circle"></i> {t('agreement.accept.accepted', { acceptedDate: moment(agreementDetails.acceptDateLocal, "YYYY-MM-DD hh:mm:ss").format("MMM D, YYYY") })}</span>
                                }
                                {
                                    agreementDetails.amountDue === 0 ?
                                        <span className="label label-success"><i className="fa fa-check-circle"></i> {t('agreement.accept.fully_paid', { paidTotal: agreementDetails.totalFormatted })}</span>
                                    : <>
                                        <span className="label" style={{ marginRight: "5px" }}>{t('agreement.accept.total', { total: agreementDetails.totalFormatted })}</span>
                                        <span className="label label-warning">{t('agreement.accept.amount_due', { amountDue: agreementDetails.amountDueFormatted })}</span>
                                    </>
                                }
                            </div>
                        </Row>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-body">
                        <div className="d-flex flex-row">
                            <div className="flex-grow-0 d-flex flex-row">
                                <ButtonToolbar>
                                    <Button variant="success" onClick={() => setShowAgreementEmailModal(true)}><i className="fa fa-envelope"/> {t('common.email')}</Button>
                                    <Button variant="outline-primary" onClick={onPrint}><i className="fa fa-print"/> {t('common.print')}</Button>
                                    <Button variant="outline-primary" onClick={onLinkClick}><i className="fa fa-link"/> {t('common.link')}</Button>
                                    {
                                        agreementDetails.isSent &&
                                            <Button variant="nolink"><i className="fa fa-circle-check fa-c-success"/> {t('agreement.list.sent', { sendDate: moment(agreementDetails.sentDateLocal, "YYYY-MM-DD hh:mm:ss").format("MMM D, YYYY hh:mm a") })}</Button>
                                    }
                                </ButtonToolbar>
                            </div>
                            <div className="flex-grow-1 d-flex flex-row justify-content-end">
                            {
                                !agreementDetails.isCancel &&
                                    <ButtonToolbar>
                                        {
                                            !agreementDetails.isCancel && agreementDetails.amountDue > 0 && settings.paymentProcessSetupComplete && (isAgreementAdmin || agreementDetails.agreementOnlinePaymentOption !== "no") &&
                                                <Button variant="success" onClick={onPay}>{t('common.online_payment')}</Button>
                                        }
                                        {
                                            !agreementDetails.isCancel && isAgreementAdmin &&
                                                <SingleSelectDropdown className="inline" toggleLabel={<span><i className="fa fa-small fa-circle-plus"/> {t('common.invoice')}</span>} items={invoiceOptions} onSelect={onInvoiceSelected} align="end" menuOnly/>
                                        }
                                        {
                                            isAgreementAdmin && !agreementDetails.isCancel  && (agreementDetails.isAccepted ?
                                                <ConfirmationButton variant="outline-primary" onConfirm={() => onMark(false)}><i className="fa fa-circle-check"/> {t('agreement.list.mark_unaccepted')}</ConfirmationButton>
                                            : <ConfirmationButton variant="outline-primary" onConfirm={() => onMark(true)}><i className="fa fa-circle-check"/> {t('agreement.list.mark_accepted')}</ConfirmationButton>)
                                        }
                                        {
                                            isAgreementAdmin &&
                                                <Link to={`/${facilityLink}/agreement/update?uuid=${uuid}&groupId=${groupId}`}><Button variant="outline-primary"><i className="fa fa-edit"/> {t('common.edit')}</Button></Link>
                                        }
                                        { isAgreementAdmin && !agreementDetails.isCancel && <DeleteButton size="md" onDelete={cancelAgreement} title={t('agreement.list.delete_confirm_title')} body={t('agreement.list.delete_confirm_body')}/> }
                                    </ButtonToolbar>
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-box">
                    <div className={classnames("content-body", "invoice-container", agreementDetails.isCancel ? "cancelled" : "")}>
                        <Row className="invoice-header">
                            <Col md="3">
                                <h2>{ settings.agrTitle }</h2>
                                { agreementDetails.group && <strong>{ agreementDetails.group.name }<br/></strong> }
                                { agreementDetails.to && <span>{ agreementDetails.to }<br/></span> }
                                { agreementDetails.group && agreementDetails.group.email && <span>{ agreementDetails.group.email }<br/></span> }
                                { agreementDetails.group && agreementDetails.group.phone && <span>{ agreementDetails.group.phone }<br/></span> }
                            </Col>
                            <Col md="3">
                                {
                                    !_.isEmpty(agreementDetails.address) &&
                                        <>
                                            <strong><div dangerouslySetInnerHTML={ { __html: agreementDetails.address.companyName } } /></strong>
                                            { agreementDetails.address.address1 }{ agreementDetails.address.address2 && ` ${agreementDetails.address.address2}` }<br />
                                            { agreementDetails.address.city } { agreementDetails.address.state } { agreementDetails.address.zipCode }<br/>
                                            { agreementDetails.address.phone }
                                        </>
                                }
                            </Col>
                            <Col md="3">
                                <Row>
                                    <Col md="6"><strong>Agreement date:</strong></Col>
                                    <Col md="6" className="text-end">{moment(agreementDetails.agreementDate).format("MMM DD, YYYY")}</Col>
                                    <Col md="12" className="spacing"></Col>
                                    <Col md="6"><strong>Agreement No:</strong></Col>
                                    <Col md="6" className="text-end">{agreementDetails.agreementNumber}</Col>
                                    <Col md="12" className="spacing"></Col>
                                    {
                                        settings.showBusinessNumber &&
                                            <>
                                                <Col md="6"><strong>Business No:</strong></Col>
                                                <Col md="6" className="text-end">{settings.businessNumber}</Col>
                                            </>
                                    }
                                </Row>
                            </Col>
                            <Col md="3" className="text-end">
                                <img className="invoice-logo" src={getFormattedImageLink(settings.logo, "https://rectimes.app/uploads/logoinv/")} />
                            </Col>
                        </Row>
                        {
                            agreementDetails.openingFilled &&
                                <div>
                                    <br/>
                                    <div dangerouslySetInnerHTML={ { __html: agreementDetails.openingFilled } } />
                                    <br/>
                                </div>
                        }
                        <Table hover>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th width={tableColumnWidths["item"]}>Item</th>
                                    <th width={tableColumnWidths["venue"]}>Venue</th>
                                    <th width={tableColumnWidths["description"]}>Description</th>
                                    {
                                        settings.showAgrEventName &&
                                            <th width={tableColumnWidths["event"]}>Event</th>
                                    }
                                    <th width={tableColumnWidths["hours"]} className="text-end">Hours</th>
                                    <th width={tableColumnWidths["rate"]} className="text-end">Rate</th>
                                    <th width={tableColumnWidths["tax"]} className="text-end">Tax</th>
                                    <th width={tableColumnWidths["amount"]} className="text-end">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                _.map(agreementDetails.lineItems, (item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>
                                            {
                                                item.bookingId > 0 &&
                                                    <Button variant="outline-primary" onClick={() => editBooking(item)}><i className="fa fa-edit"/></Button>
                                            }
                                            </td>
                                            <td width={tableColumnWidths["item"]}>{item.item}</td>
                                            <td width={tableColumnWidths["venue"]}>{item.venueName}</td>
                                            <td width={tableColumnWidths["description"]}>
                                            {
                                                item.bookingId > 0 ?
                                                    <a href={`/${facilityLink}/index?lid=${item.bookingId}`}>
                                                        {item.description}
                                                    </a>
                                                : <span>{item.description}</span>
                                            }
                                            </td>
                                            {
                                                settings.showAgrEventName &&
                                                    <td width={tableColumnWidths["event"]}>{item.eventName}</td>
                                            }
                                            <td width={tableColumnWidths["hours"]} className="text-end">{item.hours}</td>
                                            <td width={tableColumnWidths["rate"]} className="text-end">{item.rateFormatted}</td>
                                            <td width={tableColumnWidths["tax"]} className="text-end">{item.taxRate}%</td>
                                            <td width={tableColumnWidths["amount"]} className="text-end">{item.amountFormatted}</td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                        <Table hover borderless className="skinny">
                            <tbody>
                                {
                                    !settings.hideAgreementTax &&
                                        <>
                                            <tr>
                                                <td width="50%"></td>
                                                <td width="40%" className="text-end">Subtotal</td>
                                                <td width="10%" className="text-end">{agreementDetails.subTotalFormatted}</td>
                                            </tr>
                                            { _.map(taxRateMap, (taxAmount, taxLabel, j) =>
                                                <tr key={j}>
                                                    <td width="50%"></td>
                                                    <td width="40%" className="text-end">{taxLabel}</td>
                                                    <td width="10%" className="text-end">${taxAmount.toFixed(2)}</td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td width="50%"></td>
                                                <td width="40%" className="text-end">Total Tax</td>
                                                <td width="10%" className="text-end">{agreementDetails.totalTaxFormatted}</td>
                                            </tr>
                                        </>
                                }
                                <tr>
                                    <td width="50%"></td>
                                    <td width="40%" className="text-end upline"><strong>Total</strong></td>
                                    <td width="10%" className="text-end upline"><strong>{agreementDetails.totalFormatted}</strong></td>
                                </tr>
                            </tbody>
                        </Table>
                        <br/>
                        <Table hover borderless className="skinny">
                            <tbody>
                            {
                                _.map(_.filter(agreementDetails.payments, (p) => p.amount > 0), (payment, i) =>
                                    <tr key={i}>
                                        <td width="90%" className="text-end">
                                        {
                                            isAgreementAdmin &&
                                                <Button variant="outline-primary" size="sm" onClick={() => deletePayment(payment)}><i className="fa fa-trash"/></Button>
                                        }
                                            &nbsp;Less Payment {moment(payment.datePaid).format("MMM D, YYYY")} ({payment.paymentMethodName}){payment.reference ? ` ${payment.reference}` : ""}
                                        </td>
                                        <td width="10%" className="text-end"><strong>{ currencyFormat(payment.amount) }</strong></td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </Table>
                        <Table hover borderless>
                            <tbody>
                                <tr>
                                    <td width="50%"></td>
                                    <td width="40%" className="text-end upline"><strong>Amount Due</strong></td>
                                    <td width="10%" className="text-end upline"><strong>{agreementDetails.amountDueFormatted}</strong></td>
                                </tr>
                            </tbody>
                        </Table>
                        {
                            !agreementDetails.hideMonthlyCharges &&
                                <div>
                                    <h2 className="invoice-header">Monthly Charges</h2>
                                    <Table hover className="skinny">
                                        <thead>
                                            <tr>
                                                <th width="35%">Date</th>
                                                <th>Hours</th>
                                                <th>Subtotal</th>
                                                <th>Tax</th>
                                                <th>Monthly Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            _.map(agreementDetails.monthlyCharges, (mc, i) =>
                                                <tr key={i}>
                                                    <td width="35%">{ moment(mc.startDate).format("MMM, YYYY") }</td>
                                                    <td>{ mc.hours.toFixed(2) }</td>
                                                    <td>{ currencyFormat(mc.subTotal) }</td>
                                                    <td>{ currencyFormat(mc.taxAmount) }</td>
                                                    <td>{ currencyFormat(mc.total) }</td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                    <br/>
                                </div>
                        }
                        {
                            agreementDetails.notes &&
                                <div className="invoice-subdetails" dangerouslySetInnerHTML={ { __html: agreementDetails.notes } } />
                        }
                        {
                            agreementDetails.contractFilled &&
                                <div>
                                    <h2 className="invoice-header">Usage Contract</h2>
                                    <div className="invoice-subdetails" dangerouslySetInnerHTML={ { __html: agreementDetails.contractFilled } } />
                                </div>
                        }
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-body invoice-container">
                        <div className="invoice-header">
                            <h2>Accept Agreement</h2>
                            {
                                isExpired ?
                                    <Alert style={{ fontSize: "28px" }} variant="warning">
                                        This agreement expired on: {moment(agreementDetails.dueDateLocal, "YYYY-MM-DD hh:mm:ss").format(getDateFormatForFacility(settings) )}.
                                        Please contact the facility admin for further details.
                                    </Alert>
                                : !agreementDetails.isAccepted && needsLogin && (
                                    !isLoggedIn ?
                                        <Alert style={{ fontSize: "30px" }} variant="warning">Please login in order to accept the agreement</Alert>
                                    : !isExpectedUser &&
                                        <Alert style={{ fontSize: "30px" }} variant="warning">Please login to { `${!_.isEmpty(agreementDetails) && agreementDetails.group.name}'s account` } in order to accept the agreement</Alert>
                                )
                            }
                            {
                                showAcceptForm &&
                                    <BaseForm initialFormFields={initialAcceptFormFields} onSubmit={onAccept}>
                                        <Row>
                                            <BaseForm.Input colSpan="6" type="text" name="groupName" label="Group Name" required disabled={agreementDetails.isAccepted}/>
                                            <BaseForm.Input colSpan="6" type="text" name="fullName" label="Full Name" required disabled={agreementDetails.isAccepted}/>
                                            <BaseForm.Input colSpan="6" type="text" name="email" label="Email" required validations={{ validEmail: true }} disabled={agreementDetails.isAccepted}/>
                                            <BaseForm.Input colSpan="6" type="text" name="phone" label="Phone" required disabled={agreementDetails.isAccepted}/>
                                            {
                                                !_.isEmpty(paymentMethods) &&
                                                    <BaseForm.Input colSpan="6" type="select" name="paymentMethod" label="Payment Method" options={paymentMethodOptions} showSearch={false} required disabled={agreementDetails.isAccepted}/>
                                            }
                                        </Row>
                                        {
                                            !agreementDetails.isAccepted &&
                                                <>
                                                    <Row>
                                                        <Col md="6">
                                                            <BaseForm.CheckboxRadio name="iagree" type="checkbox"
                                                                value={true} hideLabel visibleLabel="I Agree"
                                                                label={iAgreeLabel} validations={{ isChecked: true }} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="6">
                                                            <SubmitButton variant="primary">Accept Agreement</SubmitButton>
                                                        </Col>
                                                    </Row>
                                                </>
                                        }
                                    </BaseForm>
                            }
                            {
                                agreementDetails.isAccepted &&
                                    <Alert style={{ fontSize: "28px" }} variant="success">
                                        This agreement was accepted on: {moment(agreementDetails.acceptDateLocal, "YYYY-MM-DD hh:mm:ss").format("MMM D, YYYY")}
                                    </Alert>
                            }
                        </div>
                    </div>
                </div>
            </Loader>
            <SingleAgreementEmailModal show={showAgreementEmailModal} onClose={setShowAgreementEmailModal} agreement={agreementDetails} />
            <InvoiceAgreementPaymentModal show={showInvoicePaymentModal} onClose={onModalClose} agreement={agreementDetails} settings={settings} />
            <ChooseAgreementMonthModal
                show={showChooseAgreementMonthModal}
                onClose={setShowChooseAgreementMonthModal}
                groupId={groupId}
                bookingLineItems={bookingLineItemsToInvoice} />
        </BaseContainer>
    );
}

export default AgreementAccept;
