import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/forms.scss';
import BaseContainer from '../../components/Container';
import TabHeader from '../../components/TabHeader';
import BaseForm from '../../components/BaseForm';
import { serverFetch, serverPatch } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useSearchParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Alert, Row, Col, Table } from 'react-bootstrap';
import Notification from '../../components/Notification';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function MultiBooking() {
    const { getApiUrl, facilityLink, getFacilityName } = useContext(BaseContext);
    const [loading, setLoading] = useState(true);
    const [venues, setVenues] = useState([]);
    const [venueDetails, setVenueDetails] = useState({});
    const [ searchParams ] = useSearchParams();
    const venueId = searchParams.get('id');
    const { t } = useTranslation('common');

    useEffect(() => {
        document.title = `${venueDetails.name} (Multi Booking) - ${getFacilityName()}`;
    }, [venueDetails]);

    useEffect(() => {
        setLoading(true);
        serverFetch(getApiUrl(`/venues/${venueId}`)).then((res) => {
            setVenueDetails(res);
            setLoading(false);
        });

        fetchData();
    }, [venueId]);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/venues/${venueId}/multi_bookings`), { skipCache }).then((res) => {
            setVenues(_.filter(res, (r) => String(r.venueId) !== venueId));
        });
    }

    const updateChecked = (targetVenueId, event) => {
        const isChecked = event.target.checked;
        const data = {
            venueId: targetVenueId,
            selected: isChecked
        }
        serverPatch(getApiUrl(`/venues/${venueId}/multi_bookings`), data, {}).then((res) => {
            if (res) {
                Notification.Show(t('notification.successfully_updated'));
            }
            fetchData(true);
        });
    }

    const updateSettings = (name, value) => {
        const data = {};
        data[name] = value;
        // TODO(aravind): Find a way to update venue list checkboxes
        serverPatch(getApiUrl(`/venues/${venueId}/settings`), data, {}).then((res) => {
            if (res) {
                Notification.Show(t('notification.successfully_updated'));
            }
        });
    }

    const tabsParams = {
        searchParams: venueId && `id=${venueId}`,
        venueName: venueDetails.name
    };
    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "venue-settings", tabsParams)}/>
            <div className="content-box">
                <div className="content-body">
                    <Alert variant="info">
                        <strong>{t("setting.multibook.title")}:</strong> {t("setting.multibook.desc")}
                    </Alert>
                    <BaseForm initialFormFields={venueDetails} onFieldChange={updateSettings}>
                        <Row>
                            <BaseForm.Input colSpan="5" type="check" name="allowMultiBook" label={t("setting.multibook.allow")} />
                        </Row>
                    </BaseForm>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    {
                        !loading &&
                            <>
                                <Row>
                                    <Col md="4">
                                        <Table hover>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>{t("setting.venue_name")}</th>
                                                </tr>
                                            </thead>
                                            <tbody id="sortable-table">
                                                {
                                                    _.map(venues, (venue, i) =>
                                                        <tr key={i}>
                                                            <td>
                                                                <div className="checkbox check-success inline">
                                                                    <input type="checkbox" value={venue.venueId} id={venue.venueId} checked={venue.selected} onChange={ (event) => updateChecked(venue.venueId, event) }/>
                                                                    <label htmlFor={venue.venueId}/>
                                                                </div>
                                                            </td>
                                                            <td>{venue.venueName}</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </>
                    }
                </div>
            </div>
        </BaseContainer>
    );
}

export default MultiBooking;
