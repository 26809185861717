import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/forms.scss';
import BaseContainer from '../../components/Container';
import BaseForm from '../../components/BaseForm';
import TabHeader from '../../components/TabHeader';
import SubmitButton from '../../components/SubmitButton';
import { serverFetch, serverPatch } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useSearchParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Alert, Row } from 'react-bootstrap';
import Notification from '../../components/Notification';
import { useTranslation } from 'react-i18next';

function Policy() {
    const { getApiUrl, facilityLink, getFacilityName } = useContext(BaseContext);
    const [venueDetails, setVenueDetails] = useState({});
    const [ searchParams ] = useSearchParams();
    const venueId = searchParams.get('id');
    const { t } = useTranslation('common');

    useEffect(() => {
        document.title = `${venueDetails.name} (Policy) - ${getFacilityName()}`;
    }, [venueDetails]);

    useEffect(() => {
        serverFetch(getApiUrl(`/venues/${venueId}`)).then((res) => {
            setVenueDetails(res);
        });
    }, [venueId]);

    const updateSettings = (fields) => {
        serverPatch(getApiUrl(`/venues/${venueId}/settings`), fields, {}).then((res) => {
            if (res) {
                Notification.Show(t('notification.successfully_updated'));
            }
        });
    }

    const tabsParams = {
        searchParams: venueId && `id=${venueId}`,
        venueName: venueDetails.name
    };
    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "venue-settings", tabsParams)}/>
            <BaseForm initialFormFields={venueDetails} onSubmit={updateSettings}>
            <div className="content-box">
                <div className="content-body">
                    <Alert variant="info">
                        <strong>{t("setting.policy.title")}:</strong> {t("setting.policy.desc")}
                    </Alert>
                    <Row>
                        <BaseForm.Input colSpan="12" type="editor" height="650" name="rentalPolicy"
                            tokens={['{companyName}']} />
                    </Row>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <SubmitButton variant="primary">{t("common.save")}</SubmitButton>
                </div>
            </div>
            </BaseForm>
        </BaseContainer>
    );
}

export default Policy;
