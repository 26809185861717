import '../../App.scss';
import '../../css/modals.scss';
import { useState, useEffect, useRef } from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { Form, Button, Row } from 'react-bootstrap';
import JoditEditor from "jodit-react";
import { useTranslation } from 'react-i18next';
import { serverPost } from '../../helpers/server';

function AddOrEditClassFormSectionModal(props) {
    const { t } = useTranslation('common');
    const [isEditing, setIsEditing] = useState(false);

    const editor = useRef(null);
	const [content, setContent] = useState('');
	const config = { readonly: false, height: 200 };

    const addClassFormSection = (sectionFields) => {
        sectionFields['subtitle'] = content;
        props.onClassFormSectionAdded(sectionFields);
        props.onClose();
    }

    const updateClassFormSection = (sectionFields) => {
        const data = {...props.sectionToEdit, ...sectionFields};
        data['subtitle'] = content;
        props.onClassFormSectionUpdated(data);
        props.onClose();
    }

    useEffect(() => {
        setIsEditing(props.sectionToEdit !== null && props.sectionToEdit !== undefined);
        const section = props.sectionToEdit || {};
        setContent(section['subtitle']);
    }, [props.sectionToEdit, props.show]);

    return (
        <BaseModal size="lg" {...props}>
            <BaseModal.Header>
                <BaseModal.Title>{isEditing ? `${t("modal.add_edit.class_form_section.title_update")}` : `${t("modal.add_edit.class_form_section.title_add")}`}</BaseModal.Title>
            </BaseModal.Header>
            <BaseModal.Body>
                <BaseForm initialFormFields={props.sectionToEdit} onSubmit={isEditing ? updateClassFormSection : addClassFormSection}>
                    <Row>
                        <BaseForm.Input colSpan={12} name={'title'} label={t('forms.section_name')} type={'text'} required />
                    </Row>
                    <Row>
                        <h5>Description</h5>
                        <JoditEditor
                            ref={editor}
                            value={content}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                            onChange={newContent => {}}
                        />
                    </Row>
                    <br/>
                    <SubmitButton className="btn-complete">{isEditing ? `${t("modal.add_edit.class_form_section.button_update")}` : `${t("modal.add_edit.class_form_section.button_add")}`}</SubmitButton>
                </BaseForm>
            </BaseModal.Body>
        </BaseModal>
    );

}

export default AddOrEditClassFormSectionModal;
