import '../../App.scss';
import '../../css/modals.scss';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function AddOrEditAddonModal(props) {
    const { t } = useTranslation('common');

    let accountOptions = _.map(props.accounts, (a) => { return { value: a.id, label: a.account } });
    const allItem = { value: null, label: "All" };
    accountOptions.unshift(allItem);

    return (
        <BaseAddOrEditItemModal
            {...props}
            itemLabel={t("modal.add_on.title")}
            itemBaseUrl={'/addons'}
        >
            <Row>
                <BaseForm.Input colSpan="12" name="addon" label={t("addon_modal.addon")} type="text" required />
                <BaseForm.Input colSpan="6" name="rate" label={t("addon_modal.rate")} type="number" min="0" step="0.01" required />
                <BaseForm.Input colSpan="6" name="accountId" label={t("addon_modal.account")} type="select" options={accountOptions} />
                <BaseForm.Input colSpan="12" name="description" label={t("addon_modal.message")} type="textarea" />
            </Row>
        </BaseAddOrEditItemModal>
    );
}

export default AddOrEditAddonModal;
