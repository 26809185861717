import '../../App.scss';
import '../../css/modals.scss';
import { useState, useEffect, useContext } from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { getUrl, serverPost, serverPatch } from '../../helpers/server';
import { getFormFieldOptions, BaseContext } from '../../helpers/common';
import { getValueFromEvent } from '../../helpers/input';
import Creatable, { useCreatable } from 'react-select/creatable';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function AddOrEditClassFormFieldModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [type, setType] = useState(null);
    const [selectOptions, setSelectOptions] = useState([]);
    const [classFormFieldFields, setClassFormFieldFields] = useState({});
    const [isEditing, setIsEditing] = useState(false);

    const addClassFormField = (formFieldFields) => {
        formFieldFields.options = type === "select" ? _.map(selectOptions, (o) => o.label) : [];;
        serverPost(getApiUrl('/form_fields'), formFieldFields).then((res) => {
            props.onClassFormFieldAdded(res);
            props.onClose();
        })
    }

    const updateClassFormField = (classFormFieldFields) => {
        const data = {...classFormFieldFields};
        data.options = type === "select" ? _.map(selectOptions, (o) => o.label) : [];
        serverPatch(getApiUrl('/form_fields/' + props.formFieldToEdit.id), data).then((res) => {
            props.onClassFormFieldUpdated(res);
            props.onClose();
        })
    }

    useEffect(() => {
         setIsEditing(props.formFieldToEdit !== null);
         if (props.formFieldToEdit) {
            setIsEditing(true);
            setType(props.formFieldToEdit.type);
            const options = _.map(props.formFieldToEdit.options, (o, i) => {
                return { 'label': o, 'value': o };
            })
            setSelectOptions(options);
         } else {
            setIsEditing(false);
         }
    }, [props.formFieldToEdit]);

    const typeOptions = getFormFieldOptions();

    const requiredOptions = [
        { 'label': 'Yes', 'value': true },
        { 'label': 'No', 'value': false },
    ];

    const dateFormatOptions = [
        { 'label': 'MM/DD/YYYY', 'value': "mm/dd/yy" },
        { 'label': 'DD/MM/YYYY', 'value': "dd/mm/yy" },
        { 'label': 'MM-DD-YYYY', 'value': "mm-dd-yy" },
        { 'label': 'DD-MM-YYYY', 'value': "dd-mm-yy" },
        { 'label': 'YYYY-MM-DD', 'value': "yy-mm-dd" },
        { 'label': 'YYYY-DD-MM', 'value': "yy-dd-mm" },
        { 'label': 'DD-MM', 'value': "dd-mm" },
        { 'label': 'MM-DD', 'value': "mm-dd" },
    ];

    const defaultFormField = {
        'type': 'text',
        'dateFormat': 'mm-dd-yyyy',
        'required': false
    };

    const onFieldChange = (name, value) => {
        if (name === "type") {
            setType(value);
        }
    }

    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title>{isEditing ? `${t("modal.add_edit.class_form_field.title_update")}` : `${t("modal.add_edit.class_form_field.title_add")}`}</BaseModal.Title>
            </BaseModal.Header>
            <BaseModal.Body>
                <BaseForm onFieldChange={onFieldChange} initialFormFields={props.formFieldToEdit || defaultFormField} onSubmit={isEditing ? updateClassFormField : addClassFormField}>
                    <Row>
                        <BaseForm.Input colSpan={12} name={'name'} label={t('forms.field_label')} type={'text'} placeholder={t('forms.field_name')} required />
                        <BaseForm.Input colSpan={6} name={'type'} label={t('forms.field_type')} type={'select'} options={typeOptions} required />
                        {
                            type !== "agree" && type !== "agreeOrDisagree" &&
                                <BaseForm.Input colSpan={6} name={'required'} label={t('forms.field_is_required')} type={'select'} options={requiredOptions} required showSearch={false} />
                        }
                        {
                            type === "select" &&
                                <Col md={12}>
                                    <h5>Choices</h5>
                                    <i>Start typing the choices and press enter after typing each option.</i>
                                    <Creatable
                                        className="creatable"
                                        components={{ DropdownIndicator: null }}
                                        value={selectOptions}
                                        isMulti
                                        isClearable={false}
                                        onChange={(newValue) => setSelectOptions(newValue)}
                                        noOptionsMessage={() => null}
                                    />
                                </Col>
                        }
                        {
                            type === "DATE" &&
                                <BaseForm.Input colSpan={6} name={'dateFormat'} label={t('forms.date_format')} type={'select'} options={dateFormatOptions} required />
                        }
                    </Row>
                    <br/>
                    <SubmitButton className="btn-complete">{isEditing ? `${t("modal.add_edit.class_form_field.title_update")}` : `${t("modal.add_edit.class_form_field.title_add")}`}</SubmitButton>
                </BaseForm>
            </BaseModal.Body>
        </BaseModal>
    );

}

export default AddOrEditClassFormFieldModal;
