import { useEffect, useState, useContext, createRef } from 'react';
import '../../App.scss';
import '../../css/forms.scss';
import BaseContainer from '../../components/Container';
import TabHeader from '../../components/TabHeader';
import BaseForm from '../../components/BaseForm';
import SubmitButton from '../../components/SubmitButton';
import DailyTimesPrimeTimesModal from '../../components/modals/DailyTimesPrimeTimesModal';
import CopyVenueSettingsModal from '../../components/modals/CopyVenueSettingsModal';
import { serverFetch, serverPatch, clearRequestCache } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { timeOptions, getOptionsFromArray, yesNoOptions } from '../../helpers/input';
import { useParams, useSearchParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Button, Row, Alert, Col } from 'react-bootstrap';
import Notification from '../../components/Notification';
import SingleSelectDropdown from '../../components/SingleSelectDropdown';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function Booking() {
    const { getApiUrl, getFacilityName } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const [venueDetails, setVenueDetails] = useState({});
    const [otherVenues, setOtherVenues] = useState([]);
    const [showDailyTimesModal, setShowDailyTimesModal] = useState(false);
    const [showPrimeTimesModal, setShowPrimeTimesModal] = useState(false);
    const [showCopyVenueSettingsModal, setShowCopyVenueSettingsModal] = useState(false);
    const [sourceVenueIdToCopyFrom, setSourceVenueIdToCopyFrom] = useState(null);
    const [loading, setLoading] = useState(true);
    const [ searchParams ] = useSearchParams();
    const venueId = searchParams.get('id');
    const { t } = useTranslation('common');
    const formRef = createRef();

    useEffect(() => {
        document.title = `${venueDetails.name} (Settings) - ${getFacilityName()}`;
    }, [venueDetails]);

    useEffect(() => {
        setLoading(true);
        fetchSettings();

        serverFetch(getApiUrl(`/venues`)).then((res) => {
            setOtherVenues(_.filter(res, (r) => String(r.id) != String(venueId)));
        });
    }, [venueId]);

    const fetchSettings = (skipCache=false) => {
        serverFetch(getApiUrl(`/venues/${venueId}`), { skipCache }).then((res) => {
            setVenueDetails(res);
            setLoading(false);
        });
    }

    const updateSettings = (data) => {
        serverPatch(getApiUrl(`/venues/${venueId}/settings`), data).then((res) => {
            if (res) {
                Notification.Show(t('notification.successfully_updated'));
                clearRequestCache();
            }
        });
    }

    const onShowDailyTimesModal = (event) => {
        event.preventDefault();
        setShowDailyTimesModal(true);
    }

    const onShowPrimeTimesModal = (event) => {
        event.preventDefault();
        setShowPrimeTimesModal(true);
    }

    const onModalClose = (didUpdate) => {
        setShowDailyTimesModal(false);
        setShowPrimeTimesModal(false);
        setShowCopyVenueSettingsModal(false);
        if (didUpdate) {
            fetchSettings(true);
        }
    }

    const onCopySettingSelected = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (formRef.current) {
            const formData = formRef.current.getFormData();
            if (!formData.copyFrom) {
                Notification.Show("Please select the venue to copy the settings from.")
            } else {
                setShowCopyVenueSettingsModal(true);
                setSourceVenueIdToCopyFrom(formData.copyFrom);
            }
        }
    }

    const calendarIntervalOptions = getOptionsFromArray([5, 10, 15, 20, 25, 30]);
    const bookingIntervalOptions = getOptionsFromArray([5, 10, 15, 20, 25, 30, 60]);
    const singleClickOptions = getOptionsFromArray(_.range(5, 125, 5));
    let paddingMarginTimeOptions = getOptionsFromArray([0, 5, 10, 15, 20, 25, 30, 60]);
    paddingMarginTimeOptions = [{value: "1", label: "None"}, ...paddingMarginTimeOptions]
    const paddingMarginOptions = [
        { value: 0, label: "None"},
        { value: 1, label: "Padding"},
        { value: 2, label: "Margin"},
    ];
    const marginOptions = getOptionsFromArray(_.range(0, 60, 5));
    const endingMinutesOptions = [
        { value: "0", label: t('common.no') },
        { value: "1", label: ":15 and :45" },
        { value: "2", label: ":00 and :30" },
        { value: "3", label: ":00 only" },
        { value: "4", label: ":30 only" },
    ];
    const bookingRequestOptions = [
        { value: "no", label: t('common.no') },
        { value: "yes", label: t('common.yes') },
        { value: "afterAdvance", label: "Only after advanced booking period" }
    ];

    const tabsParams = {
        searchParams: venueId && `id=${venueId}`,
        venueName: venueDetails.name
    };

    const otherVenueOptions = _.map(otherVenues, (vv) => {
        return {
            value: vv.id,
            label: vv.name
        }
    })
    otherVenueOptions.unshift({ value: null, label: "Select Venue" });

    // TODO(Aswin): Fix the webcal url
    const renderContent = () => {
        return (
            <BaseForm ref={formRef} initialFormFields={venueDetails} onSubmit={updateSettings}>
                <div className="content-box">
                    <div className="content-header">{t("setting.booking.calendar_booking_times")}</div>
                    <div className="content-body">
                    {
                        !_.isEmpty(otherVenues) &&
                            <Row>
                                <BaseForm.Input
                                    colSpan={3} name={'copyFrom'} label={t("setting.booking.copy_from")} type={'select'} options={otherVenueOptions}
                                    rightContent={
                                        <Button variant="success" onClick={(event) => onCopySettingSelected(event)}>
                                            <i className="fa fa-save"/>
                                        </Button>
                                    }
                                    showSearch={otherVenues.length > 6}
                                />
                            </Row>
                    }
                        <Row>
                            <BaseForm.Input colSpan={3} name={'dayStart'} label={t("setting.booking.day_start")} type={'select'} options={timeOptions(5)} />
                            <BaseForm.Input colSpan={3} name={'dayEnd'} label={t("setting.booking.day_end")} type={'select'} options={timeOptions(5)} />
                        </Row>
                        <Row>
                            <BaseForm.Input colSpan={3} name={'calendarInterval'} label={t("setting.booking.calendar_interval")} type={'select'} options={calendarIntervalOptions} rightContent={"min"} />
                            <BaseForm.Input colSpan={3} name={'slotMinutes'} label={t("setting.booking.booking_interval")} type={'select'} options={bookingIntervalOptions} rightContent={"min"} />
                            <BaseForm.Input colSpan={3} name={'labelInterval'} label={t("setting.booking.label_interval")} type={'select'} options={bookingIntervalOptions} rightContent={"min"} />
                            <BaseForm.Input colSpan={3} name={'singleClick'} label={t("setting.booking.single_click")} type={'select'} options={singleClickOptions} rightContent={"min"} />
                            <BaseForm.Input colSpan={3} name={'useDailyTimes'} label={<a href="#dailyTimes" onClick={(event) => onShowDailyTimesModal(event, true)}>{t("setting.booking.use_daily")}</a>} type={'check'} />
                            <BaseForm.Input colSpan={3} name={'usePrimeTimes'} label={<a href="#primeTimes" onClick={(event) => onShowPrimeTimesModal(event, true)}>{t("setting.booking.use_prime")}</a>} type={'check'} />
                        </Row>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-header">{t("setting.booking.padding_title")}</div>
                    <div className="content-body">
                        <Row>
                            <BaseForm.Input colSpan={3} name={'isPadding'} label={t("setting.booking.padding")} type={'select'} options={paddingMarginOptions} />
                            <BaseForm.Input colSpan={3} name={'defaultPadding'} label={t("setting.booking.padding_time")} type={'select'} options={paddingMarginTimeOptions} rightContent={"min"} />
                            <BaseForm.Input colSpan={3} name={'paddingName'} label={t("setting.booking.padding_name")} type={'text'} />
                            <BaseForm.Input colSpan={3} name={'paddingUser'} label={t("setting.booking.apply_to_booking")} type={'check'} />
                        </Row>
                        <Alert variant="info">
                        <p dangerouslySetInnerHTML={{__html: t("setting.booking.padding_desc")}} />
                        </Alert>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-header">{t("setting.booking.margin_title")}</div>
                    <div className="content-body">
                        <Row>
                            <BaseForm.Input colSpan={4} name={'bookingMargin'} label={t("setting.booking.margin")} type={'select'} options={marginOptions} rightContent={"min"} />
                            <BaseForm.Input colSpan={4} name={'marginGroup'} label={t("setting.booking.margin_group_name")} type={'text'} />
                            <BaseForm.Input colSpan={4} name={'marginColor'} label={t("setting.booking.margin_color")} type={'color'} />
                        </Row>
                        <Alert variant="info">
                            {t("setting.booking.margin_desc")}
                        </Alert>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-header">{t("setting.booking.customer_options")}</div>
                    <div className="content-body">
                        <Row>
                            <BaseForm.Input colSpan={3} name={'advanceBooking'} label={t("setting.booking.advance_title")} type="number" rightContent={"Days"} min="0"/>
                            <Col md="4">
                                <BaseForm.InputGroup className="form-group">
                                    <BaseForm.Date name="advanceBookingDate" label={t("setting.booking.advance_date")} />
                                    <BaseForm.Check name="useAdvanceBookingDate" />
                                </BaseForm.InputGroup>
                            </Col>
                            <BaseForm.Input colSpan="5" name={'advanceBookingMessage'} label={t("setting.booking.advance_warning")} type={'textarea'}
                                placeholder={"Add custom message for when a customer is unable to book"} />
                        </Row>
                        <p>{t("setting.booking.advance_desc")}</p>
                        <Row>
                            <BaseForm.Input colSpan={3} name={'noBookingWithin'} label={t("setting.booking.no_booking_within")} type="number" min="0" rightContent={"Hours"} />
                            <BaseForm.Input colSpan={3} name={'noBookStart'} label={t("setting.booking.except_start")} type={'select'} options={timeOptions(5)}  />
                            <BaseForm.Input colSpan={3} name={'noBookEnd'} label={t("setting.booking.except_end")} type={'select'} options={timeOptions(5)}  />
                            <BaseForm.Input colSpan={3} name={'noBookMessage'} label={t("setting.booking.custom_msg")} type={'textarea'}
                                placeholder="Add custom message for when a customer is unable to book"/>
                        </Row>
                        <p>{t("setting.booking.hours_desc")}</p>
                        <Row>
                            <BaseForm.Input colSpan={3} name={'minUserBooking'} label={t("setting.booking.min_time")} type="number" min="0" rightContent={"Min"} />
                            <BaseForm.Input colSpan={3} name={'maxUserBooking'} label={t("setting.booking.max_time")} type="number" min="0" rightContent={"Min"} />
                            <BaseForm.Input colSpan={3} name={'minBookingGap'} label={t("setting.booking.min_gap")} type="number" min="0" rightContent={"Min"} />
                        </Row>
                        <p>{t("setting.booking.min_max_desc")}</p>
                        <Row>
                            <BaseForm.Input colSpan={3} name={'userBookInterval'} label={t("setting.booking.user_booking_interval")} type="number" min="0" rightContent={"Min"} />
                            <BaseForm.Input colSpan={3} name={'specificEndMin'} label={t("setting.booking.ending_minutes")} type={'select'} options={endingMinutesOptions} />
                        </Row>
                        <p>{t("setting.booking.interval_desc")}</p>
                        <div dangerouslySetInnerHTML={{__html: t("setting.booking.booking_options_desc")}} />
                        <Row>
                            <BaseForm.Input colSpan={3} name={'allowBookingRequest'} label={t("setting.booking.allow_request")} type={'select'} options={bookingRequestOptions} showSearch={false} />
                            <BaseForm.Input colSpan={3} name={'allowOnlineBooking'} label={t("setting.booking.allow_pay_later")} type={'select'} options={yesNoOptions(t)} showSearch={false} />
                            <BaseForm.Input colSpan={3} name={'allowCCBooking'} label={t("setting.booking.allow_CC")} type={'check'} />
                        </Row>
                        <p>{t("setting.booking.booking_options")}</p>
                        <Row>
                            <BaseForm.Input colSpan={6} name={'userCustomMsg'} label={t("setting.booking.custom_message")} type={'textarea'}
                                placeholder={"If empty, no message will show"} />
                        </Row>
                        <p>{t("setting.booking.custom_message_desc")}</p>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-header">Extra Options</div>
                    <div className="content-body">
                        <Row>
                            <BaseForm.Input colSpan={6} name={'hideUser'} label={t("setting.booking.extra.hide_group")} type={'check'} />
                            <BaseForm.Input colSpan={6} name={'hideEventName'} label={t("setting.booking.extra.hide_event_name")} type={'check'} />
                            <BaseForm.Input colSpan={6} name={'hideCalendar'} label={t("setting.booking.extra.hide_cal")} type={'check'} />
                            <BaseForm.Input colSpan={6} name={'hideEventType'} label={t("setting.booking.extra.hide_event_type")} type={'check'} />
                            <BaseForm.Input colSpan={6} name={'allowBookingColor'} label={t("setting.booking.extra.allow_color")} type={'check'} />
                            <BaseForm.Input colSpan={6} name={'notifyCustomerDefault'} label={t("setting.booking.extra.notify_customer")} type={'check'} />
                            <BaseForm.Input colSpan={6} name={'allowNotes'} label={t("setting.booking.extra.allow_notes")} type={'check'} />
                            <BaseForm.Input colSpan={6} name={'simpleUserBook'} label={t("setting.booking.extra.enable_simple_booking")} type={'check'} />
                        </Row>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-body">
                        <SubmitButton variant="primary">{t("common.save")}</SubmitButton>
                        <Button variant="outline-primary" href={`webcal://api.rectimes.com/api/v1/facilities/${facilityLink}/feed/ical?venueUUID=${venueDetails.uuid}`}><i className="fa fa-feed"/> {t("setting.booking.cal_feed")}</Button>
                    </div>
                </div>
            </BaseForm>
        );
    }
    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "venue-settings", tabsParams)}/>
            {
                !loading &&
                    renderContent()
            }
            <DailyTimesPrimeTimesModal type={showDailyTimesModal ? "dailyTimes": (showPrimeTimesModal ? "primeTimes" : null)} show={showDailyTimesModal || showPrimeTimesModal} onClose={onModalClose} settings={venueDetails} />
            <CopyVenueSettingsModal show={showCopyVenueSettingsModal} onClose={onModalClose} targetVenueId={venueId} sourceVenueId={sourceVenueIdToCopyFrom} />
        </BaseContainer>
    );
}

export default Booking;
