import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import SingleInvoiceEmailModal from '../../components/modals/SingleInvoiceEmailModal';
import InvoiceAgreementPaymentModal from '../../components/modals/InvoiceAgreementPaymentModal';
import Loader from '../../components/Loader';
import BaseContainer from '../../components/Container';
import BaseForm from '../../components/BaseForm';
import DeleteButton from '../../components/DeleteButton';
import TabHeader from '../../components/TabHeader';
import Notification from '../../components/Notification';
import SubmitButton from '../../components/SubmitButton';
import { serverFetch, serverPost, serverDelete } from '../../helpers/server';
import { downloadBlob, BaseContext, hasAccess, currencyFormat, getFormattedImageLink, getStripePublishableKeyForFacility } from '../../helpers/common';
import { getTabItems } from '../../helpers/tabs'
import { useParams, useSearchParams, Link, useNavigate } from "react-router-dom";
import { Table, Button, Row, Col, ButtonToolbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classnames from 'classnames';
import { loadStripe } from '@stripe/stripe-js';
const _ = require("lodash");

function InvoiceView() {
    const { userInfo, isLoggedIn, getApiUrl, getFacilityName, settings } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const { t } = useTranslation('common');
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [ searchParams ] = useSearchParams();
    let uuid = searchParams.get('uuid');

    // For the payment processing results
    let name = searchParams.get('name');
    let invoiceFee = searchParams.get('fee');
    let processor = searchParams.get('processor');
    let paymentIntent = searchParams.get('payment_intent');
    let paymentIntentClientSecret = searchParams.get('payment_intent_client_secret');
    let redirectStatus = searchParams.get('redirect_status');

    const [venueCategories, setVenueCategories] = useState([]);
    const [credit, setCredit] = useState(0);
    const [groupId, setGroupId] = useState(null);
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [taxRateMap, setTaxRateMap] = useState({});
    const [allPaymentMethods, setAllPaymentMethods] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [initialPaymentFormFields, setInitialPaymentFormFields] = useState({});
    const [showInvoiceEmailModal, setShowInvoiceEmailModal] = useState(false);
    const [showInvoicePaymentModal, setShowInvoicePaymentModal] = useState(false);
    
    const showPaymentForm = hasAccess("invoices", userInfo, "update") && invoiceDetails.amountDue > 0 && !invoiceDetails.isCancel;
    const isInvoiceAdmin = hasAccess("invoices", userInfo);

    useEffect(() => {
        if (!_.isEmpty(invoiceDetails)) {
            document.title = `${invoiceDetails.group.name} (${invoiceDetails.invoiceNumber}) - ${getFacilityName()}`;
            
            const taxRateMap = invoiceDetails.lineItems.reduce((map, line) => {
                const key = line.taxLabel ? `${line.taxLabel} ${line.taxRate}%` : `${invoiceDetails.taxLabel} ${line.taxRate}%`;
                map[key] = (map[key] || 0) + line.taxAmount;
                return map;
            }, {});
            setTaxRateMap({...taxRateMap})
        } else {
            document.title = `Invoice - ${getFacilityName()}`;
        }
    }, [invoiceDetails]);

    useEffect(() => {
        fetchData(true);
    }, [uuid]);

    useEffect(async () => {
        if (!paymentIntent) {
            return;
        }
        if (processor !== "stripe") {
            return;
        }
        const stripe = await loadStripe(getStripePublishableKeyForFacility(settings));
        const pi = await stripe.retrievePaymentIntent(paymentIntentClientSecret)
        const fee = invoiceFee ? parseFloat(invoiceFee): 0.0
        const data = {
            redirectStatus: pi.paymentIntent.status,
            paymentProcessorData: {
                stripe: {
                    paymentIntentId: paymentIntent
                }
            },
            amount: (parseFloat(pi.paymentIntent.amount) / 100.0) - fee,
            paymentProcessor: "stripe",
            cardHolder: name,
            invoiceFee: fee
        }

        console.log("The data is " + JSON.stringify(data));
        setTimeout(() => {
            Notification.Show("Payment Successful");
        }, 100)
        serverPost(getApiUrl(`/invoices/${uuid}/online_pay`), data).then((res) => {
            let urlParser = new URL(window.location.href);
            urlParser.searchParams.delete('payment_intent');
            urlParser.searchParams.delete('payment_intent_client_secret');
            urlParser.searchParams.delete('redirect_status');
            urlParser.searchParams.delete('processor');
            urlParser.searchParams.delete('fee');
            urlParser.searchParams.delete('name');
            navigate(urlParser.pathname + urlParser.search, { replace: true });
            fetchData(true);
        })
    }, [processor, name, invoiceFee, paymentIntent, paymentIntentClientSecret, redirectStatus]);

    useEffect(() => {
        serverFetch(getApiUrl(`/venue_categories`)).then((res) => {
            setVenueCategories(res);
        });
    }, []);

    useEffect(() => {
        fetchCredits(true);
    }, [groupId]);

    const fetchCredits = (skipCache=false) => {
        if (groupId && isInvoiceAdmin) {
            serverFetch(getApiUrl("/credits", { groupId }), { skipCache }).then((res) => {
                const total = _.sumBy(res, (r) => (r.amount * (r.creditType === "debit" ? -1: 1)));
                setCredit(total);
            });
        }
    }

    const fetchData = (skipCache = false) => {
        serverFetch(getApiUrl(`/invoices/${uuid}`), { skipCache }).then((res) => {
            res.creditCardFeeBaseAmountInCents = settings.creditCardFeeBaseAmountInCents;
            res.creditCardFeePercent = settings.creditCardFeePercent;
            res.creditCardFeeUsage = settings.creditCardFeeUsage;
            res.creditCardMinAmountInCents = settings.creditCardMinAmountInCents;
            setInvoiceDetails(res);
            setGroupId(res.group.id);
            setLoading(false);

            setInitialPaymentFormFields({
                'amount': Number(res.amountDue).toFixed(2),
                'paymentDate': moment().format("YYYY-MM-DD"),
                'paymentMethod': !_.isEmpty(paymentMethods) ? paymentMethods[0].paymentTag: null
            });
        });
    }

    useEffect(() => {
        if (_.isEmpty(invoiceDetails)) {
            return;
        }
        if (isLoggedIn) {
            serverFetch(getApiUrl(`/payment_methods`)).then((res) => {
                setAllPaymentMethods(res);
                setPaymentMethods(_.filter(res, (r) => r.isInvOption));
            });
        }
    }, [invoiceDetails, isLoggedIn]);

    useEffect(() => {
        uuid = searchParams.get('uuid')
    }, [searchParams]);

    const getPaymentLabelForPaymentMethod = (paymentMethod) => {
        const method = _.find(allPaymentMethods, (m) => m.paymentTag === paymentMethod);
        return !_.isNil(method) ? method.paymentType : null;
    }

    const onLinkClick = () => {
        prompt("Copy to clipboard: Ctrl+C, Enter", `${window.location.origin}/${facilityLink}/invoice/view?uuid=${uuid}`);
    }

    const onPrint = () => {
        if (_.isEmpty(invoiceDetails)) {
            Notification.Show("Please wait for the invoice to load before printing!");
            return;
        }
        let url = getApiUrl(`/invoices/${uuid}/pdf`);
        serverPost(url, {}, { noJson: true }).then((res) => {
            downloadBlob(res, `Invoice-${invoiceDetails.invoiceNumber}`);
        });
    }

    const editBooking = (booking) => {
        console.log("Edit booking " + booking.bid);
    }

    const getActionDescriptionForLog = (log) => {
        if (log.action === "add") {
            return (
                <span>
                    <i className="fa fa-book"/>&nbsp;Created
                </span>
            );
        } else if (log.action === "payment") {
            return (
                <span>
                    <i className="fa fa-money"/>&nbsp;Payment
                </span>
            );
        } else if (log.action === "email") {
            return (
                <span>
                    <i className="fa fa-envelope"/>&nbsp;Email
                </span>
            );
        } else if (log.action === "update") {
            return (
                <span>
                    <i className="fa fa-edit"/>&nbsp;Updated
                </span>
            );
        } else if (log.action === "void") {
            return (
                <span>
                    <i className="fa fa-remove"/>&nbsp;Void
                </span>
            );
        }
        // void, afterupdate
    }

    const getDetailsForLog = (log) => {
        if (log.action === "payment") {
            return (
                <span>
                    Payment received on {moment(log.payDate, "YYYY-MM-DD").format("MMM D, YYYY")} for {log.totalFormatted} ({getPaymentLabelForPaymentMethod(log.paymentMethod)})
                </span>
            );
        } else if (log.action === "email") {
            return (
                <span>
                    Invoice sent to {log.note} on {moment(log.changedDateLocal, "YYYY-MM-DD hh:mm:ss").format("MMM D, YYYY")}
                </span>
            );
        } else if (log.action === "update") {
            return (
                <span>
                    Price changed from {log.totalFormatted} to {log.afterTotalFormatted}
                </span>
            );
        } else if (log.action === "void") {
            if (!_.isNil(log.updateId)) {
                return (
                    <span>
                        Invoice cancelled on {moment(log.changedDateLocal, "YYYY-MM-DD hh:mm:ss").format("MMM D, YYYY")}
                    </span>
                );
            } else {
                return (
                    <span>
                        Payment voided on {moment(log.changedDateLocal, "YYYY-MM-DD hh:mm:ss").format("MMM D, YYYY")} for {log.totalFormatted}
                    </span>
                );
            }
        }
    }

    const addPayment = (paymentFields) => {
        paymentFields['paymentDateLocal'] = moment(paymentFields['paymentDate']).utcOffset(0, true).format();
        serverPost(getApiUrl(`/invoices/${uuid}/payments`), paymentFields).then((res) => {
            if (res) {
                Notification.Show(t('invoice.view.successfully_added_payment'));
                fetchData(true);
                fetchCredits(true);
            }
        });
    }

    const deletePayment = (payment) => {
        serverDelete(getApiUrl(`/invoices/${uuid}/payments/${payment.id}`)).then((res) => {
            if (res) {
                Notification.Show("Successfully deleted payment");
                fetchData(true);
            }
        });
    }

    const cancelInvoice = () => {
        console.log("The invoice to cancel is");
        serverPost(getApiUrl(`/invoices/${uuid}/cancel`)).then((res) => {
            if (res) {
                Notification.Show("Successfully cancelled invoice");
                fetchData(true);
            }
        });
    }

    const onPay = () => {
        fetchData(true);
        setShowInvoicePaymentModal(true);
    }

    const onModalClose = (didUpdate) => {
        setShowInvoicePaymentModal(false);
        setShowInvoiceEmailModal(false);
        fetchData(true);
        fetchCredits(true);
    }

    const paymentMethodOptions = _.map(paymentMethods, (m) => { return { 'id': m.paymentTag, 'label': _.replace(m.paymentType, "Paid ", ""), 'icon': m.paymentIcon }; });

    const tabsParams = {
        searchParams: groupId && `groupId=${groupId}`,
        settings: settings,
        groupId: groupId,
        userGroup: invoiceDetails.group && {
            'groupName': invoiceDetails.group.name
        },
        userInfo: userInfo
    };
    const tabs = getTabItems(t, facilityLink, "invoices", tabsParams);
    if (tabs[1]) {
        tabs[1].active = true;
    }

    let tableColumnWidths = {
        "hours": "10%",
    };
    if (settings.hideTaxColumn) {
        tableColumnWidths["tax"] = "0%";
        tableColumnWidths["rate"] = "15%";
        tableColumnWidths["amount"] = "15%";
    } else {
        tableColumnWidths["tax"] = "10%";
        tableColumnWidths["rate"] = "10%";
        tableColumnWidths["amount"] = "10%";
    }
    if (settings.showAgrEventName) {
        tableColumnWidths["item"] = "10%";
        tableColumnWidths["venue"] = "14%";
        tableColumnWidths["description"] = "22%";
        tableColumnWidths["event"] = "14%";
    } else {
        tableColumnWidths["item"] = "10%";
        tableColumnWidths["venue"] = "20%";
        tableColumnWidths["description"] = "30%";
        tableColumnWidths["event"] = "0%";
    }

    let category = null;
    if (invoiceDetails.categoryId && venueCategories && !_.isEmpty(venueCategories)) {
        category = _.find(venueCategories, (v) => String(v.id) === String(invoiceDetails.categoryId))
    }

    return (
        <BaseContainer>
            <Loader loading={loading}>
                {
                    isLoggedIn && isInvoiceAdmin && <TabHeader items={tabs}/>
                }
                <div className="content-box">
                    <div className="content-body">
                        <Row>
                            <div className="col-md-6">
                            </div>
                            <div className="col-md-6 text-end">
                                {
                                    isLoggedIn && invoiceDetails.invoiceLabel &&
                                        <span className="label label-info" style={{ marginRight: "5px" }}> { invoiceDetails.invoiceLabel }</span>
                                }
                                {
                                    isLoggedIn && credit !== 0 &&
                                        <span className="label label-info" style={{ marginRight: "5px" }}><i className="fa fa-user"></i> Credit Balance: { currencyFormat(credit) }</span>
                                }
                                {
                                    invoiceDetails.amountDue === 0 ?
                                        <span className="label label-success"><i className="fa fa-check-circle"></i> Fully Paid: { moment(invoiceDetails.paidDateLocal, "YYYY-MM-DD hh:mm:ss").format("MMM D, YYYY") } | { invoiceDetails.paidAmountFormatted }</span>
                                    : <>
                                        <span className="label" style={{ marginRight: "5px" }}>Total: { invoiceDetails.totalFormatted }</span>
                                        <span className="label label-warning">Amount Due: { invoiceDetails.amountDueFormatted }</span>
                                    </>
                                }
                            </div>
                        </Row>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-body">
                        <Row>
                            <div className="col-md-6">
                                <ButtonToolbar>
                                    <Button variant="success" onClick={() => setShowInvoiceEmailModal(true)}><i className="fa fa-envelope"/> Email</Button>
                                    <Button variant="outline-primary" onClick={onPrint}><i className="fa fa-print"/> Print</Button>
                                    <Button variant="outline-primary" onClick={onLinkClick}><i className="fa fa-link"/> Link</Button>
                                    {
                                        invoiceDetails.isSent &&
                                            <Button variant="nolink"><i className="fa fa-circle-check fa-c-success"/> Sent: {moment(invoiceDetails.sentDateLocal, "YYYY-MM-DD hh:mm:ss").format("MMM D, YYYY hh:mm a")}</Button>
                                    }
                                </ButtonToolbar>
                            </div>
                            <div className="col-md-6 text-end">
                                <ButtonToolbar>
                                    {
                                        !invoiceDetails.isCancel && invoiceDetails.amountDue > 0 && settings.paymentProcessSetupComplete && invoiceDetails.invoiceOnlinePaymentOption !== "no" &&
                                            <Button variant="success" onClick={onPay}>Online Payment</Button>
                                    }
                                    { isInvoiceAdmin && !invoiceDetails.isCancel && <Link to={`/${facilityLink}/invoice/update?uuid=${uuid}`}><Button variant="outline-primary"><i className="fa fa-edit"/> Edit</Button></Link> }
                                    { isInvoiceAdmin && !invoiceDetails.isCancel && <DeleteButton size="md" onDelete={cancelInvoice} title="Cancel Invoice?" body="Are you sure you want to cancel the invoice?"/> }
                                </ButtonToolbar>
                            </div>
                        </Row>
                    </div>
                </div>
                <div className="content-box">
                    <div className={classnames("content-body", "invoice-container", invoiceDetails.isCancel ? "cancelled" : "")}>
                        <Row className="invoice-header">
                            <Col md="3">
                                <h2>{ settings.invTitle }</h2>
                                { invoiceDetails.group && <strong>{ invoiceDetails.group.name }<br/></strong> }
                                { invoiceDetails.to && <span>{ invoiceDetails.to }<br/></span> }
                                { invoiceDetails.group && invoiceDetails.group.email && <span>{ invoiceDetails.group.email }<br/></span> }
                                { invoiceDetails.group && invoiceDetails.group.phone && <span>{ invoiceDetails.group.phone }<br/></span> }
                            </Col>
                            <Col md="3">
                                {
                                    !_.isEmpty(invoiceDetails.address) &&
                                        <>
                                            <strong><div dangerouslySetInnerHTML={ { __html: invoiceDetails.address.companyName } } /></strong>
                                            <span dangerouslySetInnerHTML={ { __html: invoiceDetails.address.address1 } } />
                                            <span dangerouslySetInnerHTML={ { __html: invoiceDetails.address.address2 } } /><br/>
                                            <span dangerouslySetInnerHTML={ { __html: invoiceDetails.address.city } } />
                                            <span dangerouslySetInnerHTML={ { __html: invoiceDetails.address.state } } />
                                            <span dangerouslySetInnerHTML={ { __html: invoiceDetails.address.zipCode } } /><br/>
                                            <span dangerouslySetInnerHTML={ { __html: invoiceDetails.address.phone } } />
                                            <p>{ settings.primaryEmail }</p>
                                        </>
                                }
                            </Col>
                            <Col md="3">
                                <Row>
                                    {
                                        category &&
                                            <>
                                                <Col><strong>Category:</strong></Col>
                                                <Col className="text-end">{ category.categoryName }</Col>
                                                <Col md="12" className="spacing"></Col>
                                            </>
                                    }
                                    <Col><strong>Invoice date:</strong></Col>
                                    <Col className="text-end">{moment(invoiceDetails.invoiceDate).format("MMM DD, YYYY")}</Col>
                                    <Col md="12" className="spacing"></Col>
                                    <Col><strong>Invoice No:</strong></Col>
                                    <Col className="text-end">{invoiceDetails.invoiceNumber}</Col>
                                    <Col md="12" className="spacing"></Col>
                                    {
                                        settings.showBusinessNumber &&
                                            <>
                                                <Col><strong>Business No:</strong></Col>
                                                <Col className="text-end">{settings.businessNumber}</Col>
                                            </>
                                    }
                                    <Col md="12" className="spacing"></Col>
                                    <Col><strong>Due date:</strong></Col>
                                    <Col className="text-end">{moment(invoiceDetails.dueDate).format("MMM DD, YYYY")}</Col>
                                </Row>
                            </Col>
                            <Col md="3" className="text-end">
                                <img alt="Company Logo" className="invoice-logo" src={getFormattedImageLink(settings.logo, "https://rectimes.app/uploads/logoinv/")} />
                            </Col>
                        </Row>
                        <br/>
                        <div className="table-wrapper">
                        <Table hover>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th width={tableColumnWidths["item"]}>Item</th>
                                    <th width={tableColumnWidths["venue"]}>Venue</th>
                                    <th width={tableColumnWidths["description"]}>Description</th>
                                    {
                                        settings.showAgrEventName &&
                                            <th width={tableColumnWidths["event"]}>Event</th>
                                    }
                                    <th width={tableColumnWidths["hours"]} className="text-end">Hours</th>
                                    <th width={tableColumnWidths["rate"]} className="text-end">Rate</th>
                                    {
                                        !settings.hideTaxColumn &&
                                            <th width={tableColumnWidths["tax"]} className="text-end">Tax</th>
                                    }
                                    <th width={tableColumnWidths["amount"]} className="text-end">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                _.map(invoiceDetails.lineItems, (item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>
                                            {
                                                item.bid > 0 &&
                                                    <Button variant="outline-primary" onClick={() => editBooking(item)}><i className="fa fa-edit"/></Button>
                                            }
                                            {
                                                item.bid === 0 &&
                                                    <Button variant="outline-primary" href={`/${facilityLink}/invoice/update?uuid=${uuid}`}><i className="fa fa-edit"/></Button>
                                            }
                                            </td>
                                            <td width={tableColumnWidths["item"]}>{item.item}</td>
                                            <td width={tableColumnWidths["venue"]}>{item.venueName}</td>
                                            <td width={tableColumnWidths["description"]}>
                                            {
                                                item.bookingId > 0 ?
                                                    <a href={`https://www.rectimes.app/${facilityLink}/index?lid=${item.bookingId}`}>
                                                        {item.description}
                                                    </a>
                                                : <span>{item.description}</span>
                                            }
                                            </td>
                                            {
                                                settings.showAgrEventName &&
                                                    <td width={tableColumnWidths["event"]}>{item.eventName}</td>
                                            }
                                            <td width={tableColumnWidths["hours"]} className="text-end">{item.hours}</td>
                                            <td width={tableColumnWidths["rate"]} className="text-end">{item.rateFormatted}</td>
                                            {
                                                !settings.hideTaxColumn &&
                                                    <td width={tableColumnWidths["tax"]} className="text-end">{item.taxRate}%</td>
                                            }
                                            <td width={tableColumnWidths["amount"]} className="text-end">{item.amountFormatted}</td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                        </div>
                        <Table hover borderless className="skinny">
                            <tbody>
                                {
                                    !settings.hideInvoiceTax &&
                                        <>
                                            <tr>
                                                <td width="50%"></td>
                                                <td width="40%" className="text-end">Subtotal</td>
                                                <td width="10%" className="text-end">{invoiceDetails.subTotalFormatted}</td>
                                            </tr>
                                            { _.map(taxRateMap, (taxAmount, taxLabel) => 
                                                <tr key={taxLabel}>
                                                    <td width="50%"></td>
                                                    <td width="40%" className="text-end">{taxLabel}</td>
                                                    <td width="10%" className="text-end">${taxAmount.toFixed(2)}</td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td width="50%"></td>
                                                <td width="40%" className="text-end">Total Tax</td>
                                                <td width="10%" className="text-end">{invoiceDetails.totalTaxFormatted}</td>
                                            </tr>
                                        </>
                                }
                                <tr>
                                    <td width="50%"></td>
                                    <td width="40%" className="text-end upline"><strong>Total</strong></td>
                                    <td width="10%" className="text-end upline"><strong>{invoiceDetails.totalFormatted}</strong></td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table hover borderless className="skinny">
                            <tbody>
                            {
                                _.map(invoiceDetails.payments, (payment, i) =>
                                    <tr key={i}>
                                        <td width="90%" className="text-end">
                                        {
                                            isInvoiceAdmin &&
                                                <Button variant="outline-primary" size="sm" onClick={() => deletePayment(payment)}><i className="fa fa-trash"/></Button>
                                        }
                                            &nbsp;{payment.description}
                                        </td>
                                        <td width="10%" className="text-end"><strong>{payment.amountPaidFormatted}</strong></td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </Table>
                        <Table hover borderless>
                            <tbody>
                                <tr>
                                    <td width="50%"></td>
                                    <td width="40%" className="text-end"><strong>Amount Due</strong></td>
                                    <td width="10%" className="text-end"><strong>{invoiceDetails.amountDueFormatted}</strong></td>
                                </tr>
                            </tbody>
                        </Table>
                        {
                            invoiceDetails.notes && (isLoggedIn || facilityLink === "wkhc") &&
                                <div className="invoice-subdetails">
                                    <strong>Notes:</strong>
                                    <div dangerouslySetInnerHTML={ { __html: invoiceDetails.notes } } />
                                </div>
                        }
                    </div>
                </div>
                {
                    showPaymentForm &&
                        <div className="content-box">
                            <div className="content-body">
                                <BaseForm initialFormFields={initialPaymentFormFields} onSubmit={addPayment}>
                                    <Row>
                                        <BaseForm.Input colSpan="3" type="number" step="0.01" name="amount" label={t('payment.add.amount_paid')} leftContent="$" min="0.01" validations={{ required: true, min: 0.01 }}/>
                                        <BaseForm.Input colSpan="3" type="date" name="paymentDate" label={t('payment.add.date_paid')} rightContent={<i className="fa fa-calendar" />} />
                                        <BaseForm.Input colSpan="2" type="select" name="paymentMethod" label={t('payment.add.payment_method')} options={paymentMethodOptions} idField="id" required />
                                        <BaseForm.Input colSpan="2" type="text" name="reference" label={t('payment.add.reference')} />
                                        <div className="col-md-2 d-flex align-items-center">
                                            <SubmitButton variant="success">{t('payment.add.add_payment')}</SubmitButton>
                                        </div>
                                    </Row>
                                </BaseForm>
                            </div>
                        </div>
                }
                {
                    isLoggedIn && isInvoiceAdmin &&
                        <div className="content-box">
                            <div className="content-body">
                                <div className="table-wrapper">
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th width="30%">Invoice Action</th>
                                            <th width="20%">Admin</th>
                                            <th width="50%">Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _.map(_.filter(invoiceDetails.logs, (l) => l.action !== "afterupdate"), (log, i) =>
                                                <tr key={i}>
                                                    <td width="30%">{log.actionDescription}</td>
                                                    <td width="20%">{log.userName}</td>
                                                    <td width="50%">{log.detailDescription}</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                                </div>
                            </div>
                        </div>
                }
            </Loader>
            <SingleInvoiceEmailModal show={showInvoiceEmailModal} onClose={onModalClose} invoice={invoiceDetails} />
            <InvoiceAgreementPaymentModal show={showInvoicePaymentModal} onClose={onModalClose} invoice={invoiceDetails} settings={settings} />
        </BaseContainer>
    );
}

export default InvoiceView;
