import $ from "jquery";
import 'jquery-ui/ui/widgets/sortable';
import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import BaseContainer from '../../components/Container';
import SingleSelectDropdown from '../../components/SingleSelectDropdown';
import BaseForm from '../../components/BaseForm';
import AddOrEditClassFormSectionModal from '../../components/modals/AddOrEditClassFormSectionModal';
import AddOrEditClassFormFieldModal from '../../components/modals/AddOrEditClassFormFieldModal';
import AddOrEditClassFormModal from '../../components/modals/AddOrEditClassFormModal';
import PreviewClassFormModal from '../../components/modals/PreviewClassFormModal';
import TabHeader from '../../components/TabHeader';
import DeleteButton from '../../components/DeleteButton';
import { getUrl, serverFetch, serverPost, serverPatch, serverDelete } from '../../helpers/server';
import { getRandomString, BaseContext, getFormFieldLabel } from '../../helpers/common';
import { useParams, useSearchParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function ClassesForms() {
    const { getApiUrl, getFacilityName } = useContext(BaseContext);
    const navigate = useNavigate();
    let { facilityLink } = useParams();
    let facilityId = facilityLink;
    const { t } = useTranslation('common');
    const [ searchParams ] = useSearchParams();
    const originalSelectedClassFormId = searchParams.get("id");
    const [loadingClassForms, setLoadingClassForms] = useState(true);
    const [loadingClassFormFields, setLoadingClassFormFields] = useState(false);
    const [classForms, setClassForms] = useState([]);
    const [selectedClassForm, setSelectedClassForm] = useState(null);
    const [previewableClassForm, setPreviewableClassForm] = useState(null);
    const [selectedClassFormSections, setSelectedClassFormSections] = useState([]);
    const [selectedClassFormFields, setSelectedClassFormFields] = useState([]);
    const [initialSortedItems, setInitialSortedItems] = useState([]);

    const [classFormToEdit, setClassFormToEdit] = useState(null);
    const [formFieldToEdit, setFormFieldToEdit] = useState(null);
    const [sectionToEdit, setSectionToEdit] = useState(null);
    const [showAddOrEditClassFormFieldModal, setShowAddOrEditClassFormFieldModal] = useState(false);
    const [showAddOrEditClassFormSectionModal, setShowAddOrEditClassFormSectionModal] = useState(false);
    const [showAddOrEditClassFormModal, setShowAddOrEditClassFormModal] = useState(false);
    const [showPreviewClassFormModal, setShowPreviewClassFormModal] = useState(false);

    const [savingChanges, setSavingChanges] = useState(false);

    useEffect(() => {
        document.title = "Classes Forms - " + getFacilityName();
    }, []);

    useEffect(() => {
        if (!loadingClassForms) {
            return;
        }

        const url = getApiUrl('/forms');
        serverFetch(url, { 'skipCache': true }).then((res) => {
            setClassForms(res);
            if (!_.isEmpty(res)) {
                let selected = res[0];
                if (originalSelectedClassFormId !== null) {
                    const idform = _.find(res, r => String(r.id) === String(originalSelectedClassFormId));
                    if (idform) {
                        selected = idform
                    } else {
                        let iframeUrl = new URL(window.location.href);
                        navigate(iframeUrl.pathname, { replace: true });
                    }
                }
                setSelectedClassForm(selected);
                setSelectedClassFormFields([]);
            } else {
                setSelectedClassForm(null);
            }
            setLoadingClassForms(false);
        });
    }, [facilityLink, loadingClassForms, originalSelectedClassFormId]);

    useEffect(() => {
        // Every time a modal closes, refresh the list of class forms.
        if (!showAddOrEditClassFormModal && !loadingClassForms) {
            setLoadingClassForms(true);
        }
    }, [showAddOrEditClassFormModal]);

    useEffect(() => {
        // Every time a class form is selected, refresh the list of class form fields.
        setLoadingClassFormFields(true);
    }, [selectedClassForm]);

    useEffect(() => {
        if (!loadingClassFormFields) {
            return;
        }
        if (!selectedClassForm) {
            setLoadingClassFormFields(false);
            return;
        } else {
            let iframeUrl = new URL(window.location.href);
            iframeUrl.searchParams.set("id", selectedClassForm.id);
            const targetUrl = iframeUrl.pathname + iframeUrl.search;
            navigate(targetUrl, { replace: true });
        }
        const formUrl = getApiUrl("/forms/" + selectedClassForm.id);
        serverFetch(formUrl).then((res) => {
            let sections = [...res['sections']];
            _.forEach(sections, (s) => {
                s.id = "new-" + getRandomString(5);
            })
            setSelectedClassFormSections(sections);
            setLoadingClassFormFields(false);
        });
    }, [facilityLink, loadingClassFormFields]);

    useEffect(() => {
        $(function() {
            $('#sortable-table').sortable({
                items:'div.section',
                cursor: 'grabbing',
                stop: function(event, tbody) {},
            });

            _.forEach(selectedClassFormSections, (section, i) => {
                $('#section-fields-' + section.id).sortable({
                    items:'.class-form-field',
                    cursor: 'grabbing',
                    stop: function(event, tbody) {},
                });
            });
        });
    }, [initialSortedItems]);

    useEffect(() => {
        setPreviewableClassForm({
            sections: selectedClassFormSections
        });
    }, [selectedClassFormSections])

    // Class Form: Add, update, remove class form
    const addClassForm = () => {
        setClassFormToEdit(null);
        setShowAddOrEditClassFormModal(true);
    }

    const editClassForm = (classForm) => {
        setClassFormToEdit(classForm);
        setShowAddOrEditClassFormModal(true);
    }

    const deleteClassForm = (classForm) => {
        const deleteUrl = getApiUrl('/forms/' + classForm.id);
        serverDelete(deleteUrl).then((res) => {
            let iframeUrl = new URL(window.location.href);
            navigate(iframeUrl.pathname, { replace: true });
            // Refresh the class forms
            setLoadingClassForms(true);
        })
    }

    const onClassFormSelected = (value) => {
        console.log("on class form selected " + value + ", " + typeof(value));
        const selected = _.find(classForms, (cf) => String(cf.id) === value);
        setSelectedClassForm(selected);
        setSelectedClassFormFields([]);
    }

    const onFieldChange = (key, value) => {
    }
    // Class Form -- End

    // Class Form Field: Add, update, remove class form field
    const addFormField = (section) => {
        setSectionToEdit(section);
        setFormFieldToEdit(null);
        setShowAddOrEditClassFormFieldModal(true);
    }

    const updateFormField = (section, field) => {
        setSectionToEdit(section);
        setFormFieldToEdit(field);
        setShowAddOrEditClassFormFieldModal(true);
    }

    const deleteFormField = (section, field) => {
        const updatedSection = {...section};
        updatedSection.fields = _.filter(updatedSection.fields, (f) => f.id !== field.id);
        onClassFormSectionUpdated(updatedSection);
    }

    const onClassFormFieldAdded = (field) => {
        const updatedSection = {...sectionToEdit};
        updatedSection.fields.push(field);
        onClassFormSectionUpdated(updatedSection)
    }

    const onClassFormFieldUpdated = (field) => {
        const updatedSection = {...sectionToEdit};
        const newFields = [];
        _.forEach(updatedSection.fields, (f) => {
            if (f.id === field.id) {
                newFields.push(field);
            } else {
                newFields.push(f);
            }
        });
        updatedSection.fields = newFields;
        onClassFormSectionUpdated(updatedSection);
    }
    // Class Form Field -- End

    // Class Form Section: Add, update, remove class form section
    const addSection = () => {
        setSectionToEdit(null);
        setShowAddOrEditClassFormSectionModal(true);
    }

    const updateSection = (section) => {
        setSectionToEdit(section);
        setShowAddOrEditClassFormSectionModal(true);
    }

    const onClassFormSectionAdded = (section) => {
        const newSections = [...selectedClassFormSections];
        section['id'] = "new-" + getRandomString(5);
        section['fields'] = [];
        newSections.push(section);
        setSelectedClassFormSections(newSections);
    }

    const onClassFormSectionUpdated = (section) => {
        const newSections = [];
        _.forEach(selectedClassFormSections, (s) => {
            if (s.id === section.id) {
                newSections.push(section);
            } else {
                newSections.push(s);
            }
        });
        setSelectedClassFormSections(newSections);
    }

    const deleteSection = (section) => {
        const newSections = _.filter(selectedClassFormSections, (s) => s.id !== section.id);
        setSelectedClassFormSections(newSections);
    }

    useEffect(() => {
        setInitialSortedItems(_.map(selectedClassFormSections, (s) => s.id));
    }, [selectedClassFormSections]);
    // Class Form Section -- End

    const saveFormChanges = () => {
        const url = getApiUrl('/forms/' + selectedClassForm.id);
        const adjustedSections = [];
        const sortedSectionIds = $('#sortable-table').sortable('toArray');
         _.forEach(sortedSectionIds, (sid) => {
            const section = _.find(selectedClassFormSections, (s) => s.id === sid);
            const newSection = {...section};
            const sortedFieldIds = $('#section-fields-' + sid).sortable('toArray');
            newSection.fieldIds = sortedFieldIds;
            delete newSection['id'];
            adjustedSections.push(newSection);
        });

        setSavingChanges(true);
        const data = {'sections': adjustedSections};
        serverPatch(url, data).then((res) => {
            setSavingChanges(false);
        })
    }

    const renderField = (section, field, i) => {
        return (
            <div key={i} id={field.id} className="class-form-field">
            <Row>
                <Col md={9}>
                    <p className="class-form-field-title">{ field.name }</p>
                    <p>{ getFormFieldLabel(field.type) } <i>{ !_.isEmpty(field.options) && ("(" + field.options.join(", ") + ")") } { !_.isEmpty(field.dateFormat) && ("(" + field.dateFormat + ")") }</i></p>
                </Col>
                <Col md={3} className="text-end">
                    <Button variant="link" onClick={() => updateFormField(section, field)} ><i className="fa fa-pencil"></i></Button>
                    <Button variant="danger" onClick={() => deleteFormField(section, field)}><i className="fa fa-trash"></i></Button>
                </Col>
            </Row>
            </div>
        )
    }

    const renderSection = (section, i) => {
        return (
            <Row key={i}>
            <Col md={8}>
            <div className="section" id={section.id}>
                <Row>
                    <Col md="auto" className="section-title">
                        <h3>{ section.title }</h3>
                    </Col>
                    <Col md={3}>
                        <Button variant="link" onClick={() => updateSection(section)} ><i className="fa fa-pencil"></i></Button>
                        <DeleteButton variant="danger" onDelete={() => deleteSection(section)}><i className="fa fa-trash"></i></DeleteButton>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <div dangerouslySetInnerHTML={{__html: section.subtitle }} />
                    </Col>
                </Row>
                <div id={"section-fields-" + section.id}>
                {
                    _.map(section['fields'], (field, j) =>
                        renderField(section, field, j)
                    )
                }
                </div>
                <Row>
                    <Col md={{ span: 12 }}>
                        <Button variant="outline-primary" onClick={() => addFormField(section)}><i className="fa fa-plus"></i> {t("setting.class_setting.add_field")}</Button>
                    </Col>
                </Row>
            </div>
            </Col>
            </Row>
        )
    }

    const classFormOptions = _.map(classForms, (cf) => { return { id: String(cf.id), label: cf.name } })
    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "setting-classes")}/>
            <div className="content-box">
                <div className="content-header">
                    {t("setting.class_settings.class_forms")}
                </div>
                <div className="content-body">
                    <a className="btn btn-outline-primary" onClick={() => addClassForm()}><i className="fa fa-plus-circle"></i>{t("setting.class_settings.add_form")}</a>
                </div>
                <div className="content-header">
                {t("setting.class_settings.select_form")}
                </div>
                <div className="content-body">
                {
                    loadingClassForms ?
                        <div className="spinner-border text-secondary" role="status">
                            <span className="visually-hidden">{t('common.loading')}</span>
                        </div>
                    : <>
                        <Row>
                            {
                                !_.isEmpty(classForms) ?
                                    <>
                                        <Col sm="4">
                                            <SingleSelectDropdown items={classFormOptions} selectedId={selectedClassForm && String(selectedClassForm.id)} onSelect={onClassFormSelected}/>
                                        </Col>
                                        <Col sm="4">
                                            <Button variant="outline-primary" onClick={() => editClassForm(selectedClassForm)}><i className="fa fa-plus-circle"></i> Edit</Button>
                                            <DeleteButton variant="danger" onDelete={() => deleteClassForm(selectedClassForm)}
                                                title="Confirm Archive?" body="Are you sure you want to archive? Any data collected for the fields in this section will be lost." />
                                        </Col>
                                    </>
                                : <p>No forms to select</p>
                            }
                        </Row>
                    </>
                }
                </div>
            </div>
            {
                selectedClassForm &&
                    <>
                    <div className="content-box">
                        <div className="content-header">
                        {t("setting.class_settings.form_fields")}
                        </div>
                        <div className="content-body">
                            {
                                loadingClassFormFields ?
                                    <div className="spinner-border text-secondary" role="status">
                                        <span className="visually-hidden">{t('common.loading')}</span>
                                    </div>
                                : <>
                                    <div id="sortable-table">
                                    {
                                        _.map(selectedClassFormSections, (section, i) =>
                                            renderSection(section, i)
                                        )
                                    }
                                    </div>
                                    <Row>
                                        <Col md="12">
                                            <Button variant="outline-primary" onClick={addSection}><i className="fa fa-plus"></i> {t("setting.class_settings.add_section")}</Button>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </div>
                    </div>
                    <div className="content-box">
                        <div className="content-body">
                            <Row>
                                <Col md="12">
                                {
                                    savingChanges ?
                                    <div className="spinner-border text-secondary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    : <Button variant="primary" onClick={saveFormChanges}><i className="fa fa-save"></i> {t("setting.class_settings.save_changes")}</Button>
                                }
                                <Button variant="link" onClick={() => setShowPreviewClassFormModal(true)}>{t("setting.class_settings.preview")}</Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    </>
            }
            <AddOrEditClassFormSectionModal
                facilityId={facilityLink}
                show={showAddOrEditClassFormSectionModal}
                onClose={setShowAddOrEditClassFormSectionModal}
                onClassFormSectionAdded={(section) => onClassFormSectionAdded(section)}
                onClassFormSectionUpdated={(section) => onClassFormSectionUpdated(section)}
                sectionToEdit={sectionToEdit}
            />
            <AddOrEditClassFormFieldModal
                facilityId={facilityId}
                show={showAddOrEditClassFormFieldModal}
                onClose={setShowAddOrEditClassFormFieldModal}
                onClassFormFieldAdded={(field) => onClassFormFieldAdded(field)}
                onClassFormFieldUpdated={(field) => onClassFormFieldUpdated(field)}
                formFieldToEdit={formFieldToEdit}
            />
            <AddOrEditClassFormModal facilityId={facilityId} show={showAddOrEditClassFormModal} onClose={setShowAddOrEditClassFormModal} classFormToEdit={classFormToEdit}/>
            <PreviewClassFormModal show={showPreviewClassFormModal} onClose={setShowPreviewClassFormModal} form={previewableClassForm} />
        </BaseContainer>
    );
}

export default ClassesForms;
