import '../../App.scss';
import '../../css/modals.scss';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function AddOrEditCustomerTypeModal(props) {
    const { t } = useTranslation('common');

    return (
        <BaseAddOrEditItemModal
            {...props}
            itemLabel={t("add_customer_type_modal.title")}
            itemBaseUrl={'/customer_types'}
        >
            <Row>
                <BaseForm.Input colSpan="12" name="customerType" label={t('forms.customer_type')} type="text" placeholder="Customer Type" required />
            </Row>
        </BaseAddOrEditItemModal>
    );

}

export default AddOrEditCustomerTypeModal;
