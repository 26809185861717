import $ from "jquery";
import 'jquery-ui/ui/widgets/sortable';
import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import Loader from '../../components/Loader';
import BaseContainer from '../../components/Container';
import DeleteButton from '../../components/DeleteButton';
import AddOrEditLockerRoomModal from '../../components/modals/AddOrEditLockerRoomModal';
import TabHeader from '../../components/TabHeader';
import { serverFetch, serverPost, serverDelete } from '../../helpers/server';
import { BaseContext, hasAccess } from '../../helpers/common';
import { getTabItems } from '../../helpers/tabs'
import { Table, Button, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function LockerRoom() {
    const { getApiUrl, facilityLink, getFacilityName, userInfo } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(true);
    const [venues, setVenues] = useState([]);
    const [lockerRoomData, setLockerRoomData] = useState({});
    const [initialSortedItems, setInitialSortedItems] = useState([]);
    const [lockerRoomToEdit, setLockerRoomToEdit] = useState(null);
    const [showAddLockerRoomModal, setShowAddLockerRoomModal] = useState(false);
    const canUpdate = hasAccess("lockers", userInfo, "update");

    useEffect(() => {
        document.title = "Locker Rooms - " + getFacilityName();
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = (skipCache = false) => {
        setLoading(true);
        serverFetch(getApiUrl('/lockers'), { skipCache }).then((res) => {
            setLoading(false);
            const groupData = _.groupBy(res, 'venueId');
            const venueIds = Object.keys(groupData);
            const initialSortData = {};
            _.each(venueIds, (id) => {
                if (!_.isEmpty(res)) {
                    initialSortData[id] = _.map(groupData[id], (r) => String(r.id));
                } else {
                    initialSortData[id] = [];
                }
            })
            setLockerRoomData(groupData);
            setInitialSortedItems(initialSortData);
        })

        serverFetch(getApiUrl('/venues'), { skipCache }).then((res) => {
            setVenues(res);
        })
    }

    useEffect(() => {
        _.map(lockerRoomData, (roomData, venueId) => {
            $( function() {
                $('#sortable-table' + venueId).sortable({
                    items:'tr',
                    cursor: 'grabbing',
                    stop: function(event, tbody) {
                        const sortedItems = $(this).sortable("toArray");
                        if (JSON.stringify(sortedItems) !== JSON.stringify(initialSortedItems[venueId])) {
                            updateLockerRoomSort(venueId, sortedItems);
                        }
                    },
                    helper: function(e, tr) {
                        var $originals = tr.children();
                        var $helper = tr.clone();
                        $helper.children().each(function(index) {
                            // Set helper cell sizes to match the original sizes
                            $(this).width($originals.eq(index).width());
                        });
                        return $helper;
                    }
                });
            });
        })
    }, [lockerRoomData, initialSortedItems]);

    const updateLockerRoomSort = (venueId, orderedItems) => {
        const data = {
            'venueId': venueId,
            'ids': orderedItems
        };
        serverPost(getApiUrl('/lockers/sort'), data).then((res) => {
            fetchData(true);
        })
    }

    const onModalClose = () => {
        setShowAddLockerRoomModal(false);
        fetchData(true);
    }

    const addLockerRoom = () => {
        setLockerRoomToEdit(null);
        setShowAddLockerRoomModal(true);
    }

    const editLockerRoom = (event, room) => {
        event.preventDefault();
        setLockerRoomToEdit(room);
        setShowAddLockerRoomModal(true);
    }

    const deleteLockerRoom = (type) => {
        const deleteLockerUrl = getApiUrl(`/lockers/${type.id}`)
        serverDelete(deleteLockerUrl, {}).then((res) => {
            fetchData(true);
        })
    }

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "locker-rooms")}/>
            {
                canUpdate &&
                    <div className="content-box">
                        <div className="content-body">
                            <Button variant="outline-primary" onClick={() => addLockerRoom()}><i className="fa fa-plus-circle"></i> {t("setting.locker_room.add_locker")}</Button>
                        </div>
                    </div>
            }
            <Loader loading={loading}>
            {
                _.map(lockerRoomData, (roomData, venueId) =>
                    <div className="content-box" key={venueId}>
                        {
                            !_.isEmpty(venues) && _.find(venues, (v) => ""+v.id === venueId) &&
                                <div className="content-header">{_.find(venues, (v) => ""+v.id === venueId).name}</div>
                        }
                        <div className="content-body">
                            <Row>
                                <div className="col-lg-9">
                                    <Table hover>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>{t("setting.locker_room.locker_name")}</th>
                                                <th>{t("common.venue")}</th>
                                                <th>{t("setting.locker_room.sort_order")}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody id={"sortable-table"+venueId}>
                                        {
                                            _.map(roomData, (room, i) =>
                                                <tr key={i} id={room.id}>
                                                    <td><i className="fa fa-arrows-v" aria-hidden="true"></i></td>
                                                    <td><a href="#" onClick={canUpdate ? (event) => editLockerRoom(event, room): null}>{room.lockerName}</a></td>
                                                    <td>{room.venueName}</td>
                                                    <td>{room.sort}</td>
                                                    <td>
                                                    {
                                                        canUpdate &&
                                                            <DeleteButton onDelete={() => deleteLockerRoom(room)}></DeleteButton>
                                                    }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                </div>
                            </Row>
                        </div>
                    </div>
                )
            }
            </Loader>
            <AddOrEditLockerRoomModal show={showAddLockerRoomModal} onClose={onModalClose} venues={venues} itemToEdit={lockerRoomToEdit}/>
        </BaseContainer>
    );
}

export default LockerRoom;
