import '../App.scss';
import $ from "jquery";
import { Button, ButtonGroup } from 'react-bootstrap';
import { BaseContext, getShortDateFormatForFacility, getDateFormatForFacility } from '../helpers/common';
import TabHeader from '../components/TabHeader';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import SingleSelectDropdown from '../components/SingleSelectDropdown';
import MultiSelectDropdown from '../components/MultiSelectDropdown';
import { useEffect, useState, useContext, useRef, forwardRef, useImperativeHandle } from 'react';
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import moment from 'moment';
import {useTranslation} from "react-i18next";
import {getSupportedLocales} from "../helpers/calendar";
const _ = require('lodash');

const CalendarWrapper = forwardRef((props, ref)  => {
    const { t, i18n } = useTranslation('common');
    let { facilityLink } = useParams();
    useImperativeHandle(ref, () => ({
        clearSelection() {
            calendarRef.current._calendarApi.unselect();
        },

        gotoDate(value) {
            calendarRef.current._calendarApi.gotoDate(value);
            updateCalendarTitle();
        },

        gotoResource(resourceId) {
            gotoResource(resourceId);
        }
    }));

    const [ searchParams ] = useSearchParams();
    let vid = searchParams.get('vid');

    const navigate = useNavigate();
    const { settings } = useContext(BaseContext);
    const [resources, setResources] = useState([]);
    const [calendarMarginLeft, setCalendarMarginLeft] = useState(0);

    const venueLabel = settings.venueLabel || "Venue";
    const labelData = {
        venueLabel: venueLabel
    }
    const calendarViewOptions = [
        { id: 'single', 'label': t('calendar.view.single_venue', labelData) }
    ];
    if (resources.length > 1) {
        calendarViewOptions.push({ id: 'multi-venue', 'label': t('calendar.view.multiview_venue', labelData) });
        calendarViewOptions.push({ id: 'multi-day', 'label': t('calendar.view.multiview_day') });
    }
    calendarViewOptions.push({ id: 'timeline', 'label': "Timeline" });

    let defaultView = props.defaultView || "multi-venue";
    let defaultDayViewId = defaultView === "single" ? "week": "day";
    if (props.defaultDayView) {
        let options = ['day', '3day', 'week', 'month', 'list'];
        if (_.includes(options, props.defaultDayView)) {
            defaultDayViewId = props.defaultDayView
        }
    }

    const [view, setView] = useState(defaultView);
    const [dayViewId, setDayViewId] = useState(defaultDayViewId);
    const [calendarTitle, setCalendarTitle] = useState(null);
    const [calendarTitleShort, setCalendarTitleShort] = useState(null);
    const [selectedResources, setSelectedResources] = useState([]);
    const [activeResourceId, setActiveResourceId] = useState(null);
    const calendarRef = useRef(null);

    useEffect(() => {
        // Handler to call on window resize
        $( document ).ready(function() {
            function handleBeforePrint(event) {
                console.log("Before print");
                const scrollBars = $('.fc-scroller-harness > .fc-scroller');
                scrollBars.each((index, item) => {
                    var el = document.getElementById("calendar-wrapper");
                    var top = el.offsetTop;
                    $('.fc-col-header').width("100%");
                    $('.fc-timegrid-body').width("100%");
                    $('.fc-timegrid-body table').width("100%");
                });
                calendarRef.current._calendarApi.scrollToTime("00:00:00");
            }

            function handleAfterPrint() {
                console.log("After print");
            }

            // Add event listener
            window.addEventListener("beforeprint", handleBeforePrint);
            window.addEventListener("afterprint", handleAfterPrint);

            return () => {
                window.removeEventListener("afterprint", handleAfterPrint);
                window.removeEventListener("beforeprint", handleBeforePrint);
            }
        });
    }, []);

    useEffect(() => {
        setResources(props.resources);
        if (!_.isEqual(_.map(props.resources, (r) => r.id), _.map(resources, (r) => r.id))) {
            const preselectedResourceIds = getPreselectedVenueIds(props.resources);
            setSelectedResources(preselectedResourceIds);
            props.onResourcesSelected(preselectedResourceIds);
            if (!_.isEmpty(props.resources) && (_.isNil(activeResourceId) || !_.includes(preselectedResourceIds, activeResourceId))) {
                setActiveResourceId(props.resources[0].id);
            }
            if (props.resources.length === 1) {
                setView("single");
                setDayViewId("week");
            }
            setTimeout(() => {
                updateCalendarTitle();
            }, 30);
        }
    }, [props.resources]);

    useEffect(() => {
        if (view === "single") {
            setSelectedResources([activeResourceId]);
        }
    }, [activeResourceId]);

    useEffect(() => {
        if (!_.isNil(vid)) {
            setActiveResourceId(vid);
        }
    }, [vid])

    useEffect(() => {
        $(document).click(function(event) {
            var $target = $(event.target);
            const x = event.pageX;
            const y = event.pageY;
            let isInside = false;
            $(".ui-datepicker").each(function( index ) {
                const top = parseFloat($(this).parent().position().top - parseInt($(this).css('padding-top')));
                const left = parseFloat($(this).parent().position().left - parseInt($(this).css('padding-left')));
                const bottom = top + parseFloat($(this).height()) + parseInt($(this).css('padding-bottom'));
                const right = left + parseFloat($(this).width()) + parseInt($(this).css('padding-right'));
                const isThisInside = x >= left && x <= right && y >= top && y <= bottom;
                isInside = isThisInside || isInside;
            });
            if(!isInside && $("#calendar-date").hasClass("hasDatepicker")) {
                $('#calendar-date').datepicker( "destroy" );
            } else if($target.closest('.ui-datepicker').length === 1 &&
                $("#calendar-date").hasClass("hasDatepicker")) {
                event.preventDefault();
                event.stopPropagation();
            }
        });
    }, []);

    useEffect(() => {
        // Handler to call on window resize
        $( document ).ready(function() {
            function handleResize() {
                if ($('.calendar-title-content').first().length === 0) {
                    return;
                }
                let marginLeft = ($('.calendar-title-content').first().width()-221)/2;
                let contentLeft = $('.calendar-title-content').first().position().left;
                if ((contentLeft + marginLeft) < 5) {
                    marginLeft = 5 - contentLeft;
                }
                setCalendarMarginLeft(marginLeft);
            }

            // Add event listener
            window.addEventListener("resize", handleResize);
            // Call handler right away so state gets updated with initial window size
            handleResize();
            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        });
    }, [props.items]);

    const gotoResource = (resourceId) => {
        if (view === "single") {
            onResourceSelected(resourceId);
        }
    }

    const getPreselectedVenueIds = (allResources) => {
        const resourceIds = _.map(allResources, (v) => v.id);
        const cachedResources = localStorage.getItem(facilityLink + "_calendar_venue");
        let filteredResourceIds = [];
        let hasCachedResourceIds = false;
        if (cachedResources) {
            const preselectedResourceIds = _.map(cachedResources.split(","), (i) => parseInt(i));
            filteredResourceIds = _.map(_.filter(allResources, (r) => _.includes(preselectedResourceIds, r.id)), (r) => r.id);
            if (!_.isEmpty(filteredResourceIds)) {
                hasCachedResourceIds = true;
            }
        }
        if (hasCachedResourceIds) {
            return filteredResourceIds
        } else {
            return resourceIds;
        }
    }

    const closeDatePickerIfOpen = () => {
        if ($("#calendar-date").hasClass("hasDatepicker")) {
            $('#calendar-date').datepicker( "destroy" );
        }
    }

    const onDateClick = (event) => {
        var $target = $(event.target);
        event.preventDefault();
        event.stopPropagation();
        if ($target.closest('.ui-datepicker').length > 0) {
            return;
        }
        if ($("#calendar-date").hasClass("hasDatepicker")) {
            $('#calendar-date').datepicker( "destroy" );
        } else {
            let defaultDate = 0;
            if (calendarRef.current) {
                defaultDate = calendarRef.current._calendarApi.view.currentStart;
            }
            $('#calendar-date').datepicker({
                defaultDate: defaultDate,
                autoSize: true,
                changeYear: true,
                changeMonth: true,
                yearRange: "c-10:c+10",
                hideIfNoPrevNext: true,
                dateFormat: "yy-mm-dd",
                onSelect: (value) => {
                    $('#calendar-date').datepicker( "destroy" );
                    calendarRef.current._calendarApi.gotoDate(value);
                    updateCalendarTitle();
                }
            });
        }
    }

    const onResourceSelected = (resourceId) => {
        setActiveResourceId(resourceId);
        if (resourceId) {
            props.onResourcesSelected([resourceId]);
        }
    }

    const onResourcesSelected = (resources) => {
        setSelectedResources(resources);
        localStorage.setItem(facilityLink + "_calendar_venue", _.join(resources, ","));
        props.onResourcesSelected(resources);
    }

    const onNavigationClicked = (value, direction, duration) => {
        if (value === "today") {
            calendarRef.current._calendarApi.today();
        } else {
            const modifiedDuration = {};
            _.each(_.keys(duration), (key) => {
                modifiedDuration[key] = duration[key] * direction
            })
            calendarRef.current._calendarApi.incrementDate(modifiedDuration);
        }
        updateCalendarTitle();
    }

    const updateCalendarTitle = () => {
        if (!calendarRef.current) {
            return;
        }
        const start = moment(calendarRef.current._calendarApi.view.currentStart);
        localStorage.setItem("calendar_default_date", start.format("YYYY-MM-DD"));
        let end = moment(calendarRef.current._calendarApi.view.currentEnd);
        const numDays = end.diff(start, 'days');
        if (numDays === 1) {
            setCalendarTitle(start.format(getDateFormatForFacility(settings)));
            setCalendarTitleShort(start.format(getShortDateFormatForFacility(settings)));
        } else {
            end = end.add(-1, 'days');
            const showMonth = start.month() !== end.month();
            const showYear = start.year() !== end.year();
            const startFormat = `MMM D${showYear ? ", YYYY": ""}`;
            const endFormat = `${showMonth ? "MMM ": ""}D, YYYY`;
            const startShortFormat = `MMM D`;
            const endShortFormat = `${showMonth ? "MMM ": ""}D`;
            setCalendarTitle(`${start.format(startFormat)} - ${end.format(endFormat)}`);
            setCalendarTitleShort(`${start.format(startShortFormat)} - ${end.format(endShortFormat)}`);
        }
    }

    const onRender = (calendar) => {
        setTimeout(() => {
            updateCalendarTitle();
        }, 100);
    }

    const dayOptions = [
        { id: 'day', label: t('calendar.day_view.day') },
        { id: '3day', label: t('calendar.day_view.3days') },
        { id: 'week', label: t('calendar.day_view.week') },
        { id: 'month', label: t('calendar.day_view.month') },
        { id: 'list', label: t('calendar.day_view.list') }
    ];

    const smallDayOptions = [
        ...calendarViewOptions,
        { divider: true },
        { id: 'day', label: t('calendar.day_view.day') },
        { id: '3day', label: t('calendar.day_view.3days') },
        { id: 'week', label: t('calendar.day_view.week') },
        { id: 'month', label: t('calendar.day_view.month') },
        { id: 'list', label: t('calendar.day_view.list') }
    ];

    const getDurationForDayView = (d) => {
        let duration = { days: 1 };

        if (d === "day") {
            duration = { days: 1 };
        } else if (d === "3day") {
            duration = { days: 3 };
        } else if (d === "week") {
            duration = { weeks: 1 };
        } else if (d === "month") {
            duration = { months: 1 };
        } else if (d === "list") {
            duration = { days: 1 };
        }

        return duration;
    }

    const getViewProps = (v, d) => {
        let initialView = "timeGrid";
        let duration = getDurationForDayView(d);

        if (d === "list") {
            initialView = "list";
        } else if (v === "single") {
            if (d === "month") {
                initialView = "dayGrid";
            } else {
                initialView = "resourceTimeGrid";
            }
        } else if (v === "multi-venue") {
            if (d === "month") {
                initialView = "resourceDayGrid";
            } else {
                initialView = "resourceTimeGrid";
            }
        } else if (v === "timeline") {
            initialView = "resourceTimeline";
        }

        const result = {
            initialView,
            duration
        };
        return result;
    }

    const onViewSelected = (value) => {
        const viewProps = getViewProps(value, dayViewId);
        calendarRef.current._calendarApi.changeView(viewProps.initialView);
        setView(value);
        if (value === "single") {
            props.onResourcesSelected(activeResourceId ? [activeResourceId]: []);
            setSelectedResources([activeResourceId]);
            setDayViewId("week");
        } else {
            setDayViewId("day");
            const preselectedResourceIds = getPreselectedVenueIds(resources);
            setSelectedResources(preselectedResourceIds);
            props.onResourcesSelected(preselectedResourceIds);
        }
        setTimeout(() => {
            updateCalendarTitle();
        }, 100);
        let urlParser = new URL(window.location.href);
        const mMap = {
            "single": "0",
            "multi-venue": "1",
            "multi-day": "2",
            "timeline": "3"
        }
        urlParser.searchParams.set('m', mMap[value]);
        navigate(urlParser.pathname + urlParser.search, { replace: true });
    }

    const onDayViewSelected = (value) => {
        if (_.includes(["single", "multi-venue", "multi-day", "timeline"], value)) {
            onViewSelected(value);
            return;
        }
        const viewProps = getViewProps(view, value);
        calendarRef.current._calendarApi.changeView(viewProps.initialView);
        setTimeout(() => {
            updateCalendarTitle();
        }, 100);
        setDayViewId(value);
    }

    const onPlaceholderSelect = (info) => {
        closeDatePickerIfOpen();
        if (view === "single" || view === "multi-day") {
            const resource = _.find(resources, (r) => String(r.id) === String(activeResourceId));
            info.resource = resource;
        }
        let resource = null;
        let singleClick = info.resource.singleClick;
        let eventExtended = false;
        if (info.resource.id) {
            resource = _.find(resources, (r) => String(r.id) === String(info.resource.id));
            if (resource.singleClick) {
                singleClick = resource.singleClick;
            }
        }
        if (resource && (info.end.getTime() - info.start.getTime() < 1000*60*singleClick)) {
            const data = {
                start: info.start,
                end: new Date(info.start.getTime() + 1000*60*singleClick),
                allDay: false,
                resourceId: info.resource.id
            }
            calendarRef.current._calendarApi.select(data);
            eventExtended = true;
        }

        if (!eventExtended) {
            if (props.onSelect) {
                props.onSelect(info);
            }
        }
    }

    const onDrop = (info) => {
        if (props.onDrop) {
            props.onDrop(info);
        }
    }

    const onResize = (info) => {
        if (props.onDrop) {
            props.onDrop(info);
        }
    }

    const renderNavigationButtons = () => {
        return (
            <>
            { props.showWeekSkip >= 2 && <Button variant="outline-primary" onClick={() => onNavigationClicked("duration", -1, { months: 1 })}>&lt; M</Button> }
            { props.showWeekSkip >= 1 && <Button variant="outline-primary" onClick={() => onNavigationClicked("duration", -1, { weeks: 1 })}>&lt; W</Button> }
            <Button variant="outline-primary" onClick={() => onNavigationClicked("duration", -1, getDurationForDayView(dayViewId))}>&lt;&lt;</Button>
            <Button variant="outline-primary" onClick={() => onNavigationClicked("today")}>{t('common.today')}</Button>
            <Button variant="outline-primary" onClick={() => onNavigationClicked("duration", 1, getDurationForDayView(dayViewId))}>&gt;&gt;</Button>
            { props.showWeekSkip >= 1 && <Button variant="outline-primary" onClick={() => onNavigationClicked("duration", 1, { weeks: 1 })}>W &gt;</Button> }
            { props.showWeekSkip >= 2 && <Button variant="outline-primary" onClick={() => onNavigationClicked("duration", 1, { months: 1 })}>M &gt;</Button> }
            </>
        )
    }

    const onCalendarClicked = (info) => {
        console.log("onCalendarClicked ");
    }

    const onDatesSet = (info) => {
        const data = {...info};
        if (dayViewId === "list") {
            const resource = _.find(resources, (r) => String(r.id) === String(activeResourceId));
            if (resource) {
                const hour = parseInt(resource.slotMaxTime.substring(0, 2))
                if (hour > 24) {
                    let endTime = moment(info.endStr);
                    endTime.hour(hour - 24)
                    data.endStr = endTime.format();
                }
            }
        }
        if (props.onDatesSet) {
            props.onDatesSet(data);
        }
    }

    const tabItems = _.map(resources, (resource, i) => {
        return {
            label: resource.title,
            id: resource.id,
            active: String(activeResourceId) === String(resource.id),
            onClick: (item) => {
                onResourceSelected(item.id);
            }
        }
    });

    const sortedResourceList = resources;
//    const sortedResourceList = _.orderBy(resources, (r) => r.order, 'asc');
    const filteredResources = _.filter(sortedResourceList, (r) => _.includes(selectedResources, r.id));
    const resourceOptions = _.map(sortedResourceList, (resource, i) => { return { 'id': resource.id, 'label': resource.title } });
    let filteredBookings = props.events;
    if (view === "single") {
        filteredBookings = _.filter(filteredBookings, (b) => b.resourceId === activeResourceId);
    }
    const slotMinTime = _.min(_.map(filteredResources, (r) => r.slotMinTime));
    const slotMaxTime = _.max(_.map(filteredResources, (r) => r.slotMaxTime));
    const slotDurationMins = _.min(_.map(filteredResources, (r) => r.calendarInterval));
    const slotLabelIntervalMins = _.min(_.map(filteredResources, (r) => r.labelInterval));
    const slotDuration = slotDurationMins && `00:${_.padStart(slotDurationMins, 2, "0")}:00`;
    const slotLabelInterval = slotLabelIntervalMins && `00:${_.padStart(String(slotLabelIntervalMins), 2, '0')}:00`;
    return (
        <>
            {
                view === "single" &&
                    <TabHeader items={tabItems} />
            }
            <div className="content-box full">
                <div className="content-body full">
                    <div id="calendar-wrapper" className="calendar-wrapper">
                        <div className="calendar-toolbar d-flex flex-row">
                            <div className="text-start flex-grow-0">
                                <SingleSelectDropdown className="inline d-none d-md-inline-block" selectedId={view} items={calendarViewOptions} onSelect={onViewSelected} menuOnly hideLabelWhenSmall={true} />
                                {
                                    view === "multi-day" &&
                                        <SingleSelectDropdown className="inline" selectedId={activeResourceId} items={resourceOptions} onSelect={onResourceSelected} menuOnly/>
                                }
                                {
                                    view === "multi-venue" &&
                                        <MultiSelectDropdown className="inline" items={resourceOptions} selectedItems={selectedResources} onItemsChange={onResourcesSelected} />
                                }
                            </div>
                            <div className="calendar-title flex-grow-1 d-flex">
                                <div className="calendar-title-content" onClick={onDateClick}>
                                    <i className="fa calendar-icon fa-calendar-days"/><span>&nbsp;&nbsp;</span>
                                    <div className="title d-none d-md-inline-block">{ calendarTitle }</div>
                                    <div className="title title-bordered d-md-none">{ calendarTitleShort }</div>
                                    <div id="calendar-date" className="calendar-date-selector" style={{ width:"221px", margin: "0 auto", position:"absolute", marginLeft: `${calendarMarginLeft}px` }} />
                                </div>
                            </div>
                            <div className="text-end flex-grow-0">
                                <ButtonGroup>
                                    { renderNavigationButtons() }
                                    <SingleSelectDropdown className="inline d-none d-md-inline-block" align="end" selectedId={dayViewId} items={dayOptions} onSelect={onDayViewSelected} menuOnly/>
                                    <SingleSelectDropdown className="inline d-inline-block d-md-none" align="end" items={smallDayOptions} onSelect={onDayViewSelected} hideLabelWhenSmall={true} menuOnly/>
                                </ButtonGroup>
                            </div>
                        </div>
                        <div className="calendar-outer">
                            <FullCalendar
                                ref={calendarRef}
                                locales={getSupportedLocales()}
                                locale={i18n.language}
                                schedulerLicenseKey='0233246661-fcs-1672446924'
                                plugins={[resourceTimelinePlugin, resourceTimeGridPlugin, resourceDayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                                resourceOrder="order"
                                eventDisplay="block"
                                resources={filteredResources}
                                viewDidMount={onRender}
                                allDaySlot={false}
                                slotEventOverlap={false}
                                firstDay={props.weekStart || 0}
                                nowIndicator={true}
                                slotDuration={slotDuration || "00:30:00"}
                                slotLabelInterval={slotLabelInterval || "00:30:00"}
                                slotMinTime={slotMinTime || "00:00:00"}
                                slotMaxTime={slotMaxTime || "24:00:00"}
                                selectable={true}
                                selectMirror={true}
                                editable={props.isEditable}
                                eventDrop={onDrop}
                                eventResize={onResize}
                                expandRows={true}
                                dragScroll={true}
                                datesSet={onDatesSet}
                                eventContent={props.eventContent}
                                eventResourceEditable={true}
                                select={onPlaceholderSelect}
                                dateClick={onCalendarClicked}
                                events={filteredBookings}
                                headerToolbar={{ start: '', center: '', end: '' }}
                                {...getViewProps(view, dayViewId)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
})

export default CalendarWrapper;
